export const readTokenHeld = (data) => {
  try {
    const csvContent = new TextDecoder("utf-8").decode(data);
    const rows = csvContent.trim().split("\n");

    let tokenHeld;
    let profit = 0;
    // Read token held from the first CSV (csv_data)
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].split(",");
      tokenHeld += row[1];
      profit = profit + Number(exponentialToDecimal(Number(row[5])));
    }

    return { tokenHeld, profit };
  } catch (error) {
    console.log("error=--=-=-", error);
  }
};
const exponentialToDecimal = (exponential) => {
  if (Math.abs(exponential) < 1.0) {
    var e = parseInt(exponential.toString().split("e-")[1]);
    if (e) {
      exponential *= Math.pow(10, e - 1);
      exponential =
        "0." + new Array(e).join("0") + exponential.toString().substring(2);
    }
  } else {
    var e = parseInt(exponential.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      exponential /= Math.pow(10, e);
      exponential += new Array(e + 1).join("0");
    }
  }
  return Number(exponential);
};

export const walletAddress = /^(0x)?[0-9a-fA-F]{40}$/;
export const tokenAddressRegex = /^0x[0-9A-Fa-f]{40}$/;
export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value.slice(0, selectionStart) + value.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}
export function sortAddressTrans(add) {
  const sortAdd = `${add?.slice(0, 30)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}
