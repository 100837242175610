import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import {
  Box,
  Container,
  FormHelperText,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";

import { handleNegativeValue } from "src/utils";
import BackButton from "./commonbackbutton/BackButton";
import { toast } from "react-toastify";
import ButtonCircular from "./ButtonCircular";
import { useNavigate } from "react-router-dom";

export default function Deposite() {
  const [amount, setAmount] = useState("");
  console.log("amount=-=-=-")
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const depositAPIHandler = async () => {
    if (amount !== "" && Number(amount) !== 0) {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.makeContributions,
          bodyData: {
            amount_to_invest: amount,
            chatId: window.localStorage.getItem("_chId"),
          },
        });
        if (res.data) {
          setAmount("");
          if (res.data.result) {
            toast.warn(res.data.result);
          }
          if (res?.data?.value) {
            toast.warn(res?.data?.value);
          }
          if (!res?.data?.value && !res.data.result) {
            toast.success("Amount contributed successfully!");
          }
          navigate(-1);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <Box mt={2}>
      <Container>
        <BackButton title="Make Deposit" />
        <Box>
          <TextField
            onKeyDown={(event) => handleNegativeValue(event)}
            value={amount}
            onChange={(e) => {
              if (Number(e.target.value) > 100) {
                e.target.value = "100";
              }
              setAmount(e.target.value);
            }}
            fullWidth
            variant="outlined"
            placeholder="Enter amount"
            type="number"
            onKeyPress={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "*" ||
                event?.key === "/" ||
                event?.key === "e"
              ) {
                event.preventDefault();
              }
            }}
          />
          <FormHelperText error>
            {isSubmit && amount === "" && "Please enter amount"}
            {isSubmit && amount === 0 && "Please enter amount"}
          </FormHelperText>
          <Box mt={3}>
            <Button
              disabled={isLoading}
              onClick={() => depositAPIHandler()}
              variant="contained"
              color="primary"
            >
              Deposit {isLoading && <ButtonCircular />}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
