import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function TokenSeries({ alpha }) {
  const [expanded, setExpanded] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [panel] : []);
  };

  const handleChildChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        // If the accordion is expanded, add it to the expanded state
        return [...prevExpanded, panel];
      } else {
        // If the accordion is collapsed, remove it from the expanded state
        return prevExpanded.filter((item) => item !== panel);
      }
    });
  };

  return (
    <div className="alphatextBox">
      <Accordion
        expanded={expanded.includes("mainAccordion")}
        onChange={handleChange("mainAccordion")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "#fff",
                background: "#232323",
                borderRadius: "50%",
                fontSize: "15px",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1">Liquidity,Volume & Price Change</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion
            expanded={expanded.includes("liquidity")}
            onChange={handleChildChange("liquidity")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "#fff",
                    background: "#232323",
                    borderRadius: "50%",
                    fontSize: "15px",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body1">Liquidity</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="displaySpacebetween">
                <Typography variant="body1">Base</Typography>
                <Typography variant="body1">
                  {alpha?.liquidity?.base !== undefined
                    ? alpha.liquidity.base !== 0
                      ? alpha.liquidity.base
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Quote</Typography>
                <Typography variant="body1">
                  {alpha?.liquidity?.quote !== undefined
                    ? alpha.liquidity.quote !== 0
                      ? alpha.liquidity.quote
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">USD</Typography>
                <Typography variant="body1">
                  {alpha?.liquidity?.usd !== undefined
                    ? alpha.liquidity.usd !== 0
                      ? alpha.liquidity.usd
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded.includes("volume")}
            onChange={handleChildChange("volume")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "#fff",
                    background: "#232323",
                    borderRadius: "50%",
                    fontSize: "15px",
                  }}
                />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="body1">Volume</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="displaySpacebetween">
                <Typography variant="body1">Last 1 Hour</Typography>
                <Typography variant="body1">
                  {alpha?.volume?.h1 !== undefined
                    ? alpha.volume.h1 !== 0
                      ? alpha.volume.h1
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Last 24 Hours</Typography>
                <Typography variant="body1">
                  {alpha?.volume?.h24 !== undefined
                    ? alpha.volume.h24 !== 0
                      ? alpha.volume.h24
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Last 6 Hours</Typography>
                <Typography variant="body1">
                  {alpha?.volume?.h6 !== undefined
                    ? alpha.volume.h6 !== 0
                      ? alpha.volume.h6
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Last 5 Minutes</Typography>
                <Typography variant="body1">
                  {alpha?.volume?.m5 !== undefined
                    ? alpha.volume.m5 !== 0
                      ? alpha.volume.m5
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded.includes("priceChange")}
            onChange={handleChildChange("priceChange")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "#fff",
                    background: "#232323",
                    borderRadius: "50%",
                    fontSize: "15px",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="body1">Change Interval</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="displaySpacebetween">
                <Typography variant="body1">Last 1 Hour</Typography>
                <Typography variant="body1">
                  {alpha?.priceChange?.h1 !== undefined
                    ? alpha.priceChange.h1 !== 0
                      ? alpha.priceChange.h1
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Last 24 Hours</Typography>
                <Typography variant="body1">
                  {alpha?.priceChange?.h24 !== undefined
                    ? alpha.priceChange.h24 !== 0
                      ? alpha.priceChange.h24
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Last 6 Hours</Typography>
                <Typography variant="body1">
                  {alpha?.priceChange?.h6 !== undefined
                    ? alpha.priceChange.h6 !== 0
                      ? alpha.priceChange.h6
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
              <Box className="displaySpacebetween" mt={1} mb={1}>
                <Typography variant="body1">Last 5 Minutes</Typography>
                <Typography variant="body1">
                  {alpha?.priceChange?.m5 !== undefined
                    ? alpha.priceChange.m5 !== 0
                      ? alpha.priceChange.m5
                      : 0
                    : "N/A"}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
