import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Accordion,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import DescriptionText from "./DescriptionText";
import ChangeInterval from "./ChangeInterval";
import TokenSeries from "./TokenSeries";
import { apiRouterCall } from "src/config/service";
import { IoMdCopy } from "react-icons/io";
import ApiConfig from "src/config/APICongig";
import AppContext from "src/context/AppContext";
import CommonDivider from "src/Components/CommonDivider";
import ListLoder from "src/Components/Skeletons/ListLoder";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
export default function Alpha({ checkBalance, setCheckBalance }) {
  console.log("hbdhbbggdgdbgdb", checkBalance);
  const auth = useContext(AppContext);
  const { setTokenName } = auth;
  const [isDataLoading, setDataLoading] = useState(false);

  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";
  const [tokenData, setTokenData] = useState({});
  const [validation, setValidation] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [isListGetBalanceError, setIsListGetBalanceError] = useState(false);
  const [alpha, setAlpha] = useState(null); // Changed to null initially
  console.log("alphaData..........", alpha);
  const navigate = useNavigate();
  const alphaBox = {
    "& .MuiOutlinedInput-root": {
      background: "transparent",
      outline: "none !important",
    },
  };

  const header = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": {
      background: "#9b9ba130",
      fontSize: "10px",
      borderRadius: "30px",
      fontWeight: "300",
      "& svg": {
        fontSize: "15px !important",
        color: "#9B9BA1",
      },
      "& .MuiButton-startIcon": {
        marginRight: "4px",
        margiLeft: "0px",
      },
    },
  };

  const copyTokenAddress = () => {
    navigator.clipboard.writeText(tokenAddress);
    toast.success("Token address copied to clipboard", {
      style: {
        background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
        color: "#fff",
      },
    });
  };

  const getAlphaScanData = async (tokenAddress, connectChain) => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.alphaScan,
        bodyData: {
          token_address: tokenAddress,
          active_chain: connectChain,
        },
      });
  
      setDataLoading(false);
  
      if (res.status === 400 || res.status !== 200) {
        setValidation(true);
        setIsListGetBalanceError(false);
        toast.error( "Kindly Check Your Token Address ", {
          style: {
            background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
            color: "#fff",
          },
        });
      } else {
        if (res.data) {
          setAlpha(res.data);
          setIsListGetBalanceError(true);
          setValidation(true);
          toast.success("AlphaScan data fetched successfully", {
            style: {
              background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
              color: "#fff",
            },
          });
        } else {
          setValidation(true);
          setIsListGetBalanceError(false);
          toast.error("Kindly Check Your Token Address ", {
            style: {
              background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
              color: "#fff",
            },
          });
        }
      }
    } catch (error) {
      setDataLoading(false);
      toast.error("Kindly Check Your Token Address ", {
        style: {
          background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
          color: "#fff",
        },
      });
    }
  };
  
  const getTokenBalance = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.tokenBalance,
        bodyData: {
          tokenAddress: tokenAddress,
          chatId: window.localStorage.getItem("_chId"),
          active_chain: connectChain,
        },
      });

      if (res.status === 400) {
        setValidation(false);
        setDataLoading(false);
        toast.error("Invalid input");
      } else {
        if (res.data) {
          setDataLoading(false);
          setTokenData(res.data);
          setTokenName(res.data?.tokenID);
          setIsListGetBalanceError(true);
          setValidation(true);
          setCheckBalance(res.data?.tokenBalance);
          toast.success("Token Successfully Added");
        } else {
          setDataLoading(false);
          setValidation(false);
          setIsListGetBalanceError(false);
        }
      }
    } catch (error) {
      setDataLoading(false);
      toast.error("Invalid input");
    } finally {
      setDataLoading(false);
    }
  };

  const regex = /^0x[0-9A-Fa-f]{40}$/;

  return (
    <>
      <Box sx={alphaBox}>
        <Box>
          <Box sx={header} mb={2}>
            <Typography variant="body1" className="selectaccountText" mb={1}>
              Token Address's
            </Typography>
            <Button startIcon={<IoMdCopy />} onClick={copyTokenAddress}>
              Copy
            </Button>
          </Box>
          <TextField
            className="textfield"
            fullWidth
            type="text"
            placeholder="0x00...0000"
            name="tokenAddress"
            value={tokenAddress}
            onChange={(e) => {
              if (regex.test(e.target.value)) {
                setValidation(true);
                getAlphaScanData(e.target.value, connectChain);
              } else {
                setValidation(false);
                setIsListGetBalanceError(false);
              }
              setTokenAddress(e.target.value);
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "25%",
              right: "17%",
              // transform: "translate(-50%, -50%)",
            }}
          ></Box>
          <FormHelperText error>
            {tokenAddress !== "" && !validation && "Invalid token address"}
          </FormHelperText>
        </Box>
        {validation && tokenAddress !== "" && (
          <>
            {validation && tokenAddress !== "" && isListGetBalanceError && (
              <>
                <Typography variant="body1" mt={1}>
                  Token Address details:
                </Typography>
                <Box mt={2}>
                  <CommonDivider />
                </Box>
                {isDataLoading ? (
                  [1, 2, 3, 4, 5, 6].map((item, i) => {
                    return <ListLoder key={i} />;
                  })
                ) : (
                  <>
                    <DescriptionText alpha={alpha} />
                    <TokenSeries alpha={alpha} />
                    <ChangeInterval alpha={alpha} />
                  </>
                )}

                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="chatBox"
                    fullWidth
                    onClick={() => navigate("/Chart")}
                  >
                    Chart
                  </Button>
                </Box>
              </>
            )}
            {isDataLoading &&
              [1, 2, 3, 4, 5, 6].map((item, i) => {
                return <ListLoder key={i} />;
              })}
          </>
        )}
      </Box>
    </>
  );
}
