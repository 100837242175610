import { RouterProvider } from "react-router-dom";
import router from "./router";
import ContextWrapper from "./context/ContextWrapper";

function App() {
  return (
    <>
      <ContextWrapper>
        <RouterProvider router={router} />
      </ContextWrapper>
    </>
  );
}

export default App;
