import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const mainContainer = {
  padding: "0px",
  "& .Mui-expanded": {
    minHeight: "40px",
  },
  "& .MuiAccordionSummary-content": {
    display: "block",
  },
  "& h5": {
    color: "#FFF",
  },
  "& .amountText": {
    color: "rgba(255, 255, 255, 0.75)",
    fontSize: "12px",
  },
};
const shareBtn = {
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.12)",
};
const descriptionText = {
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.12)",
};
export default function AccordinCard({ item }) {
  const navigate = useNavigate();
  return (
    <Accordion
      sx={{
        background: "#202020",
        border: "none",
        margin: "3px 0px",
        padding: "10px",
        borderRadius: "10px 10px 0 0px !important",
      }}
    >
      <AccordionSummary
        sx={{ justifyContent: "space-between", minHeight: "40px" }}
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: "#fff",
              background: "#2B2B2B",
              borderRadius: "50%",
            }}
          />
        }
        aria-controls={item}
        id={item}
      >
        <Box style={{ width: "100%" }} mr={1}>
          <Box className="displaySpacebetween">
            <Typography
              variant="subTitle1"
              style={{ color: "rgba(255, 255, 255, 0.75)" }}
            >
              Token ID:
            </Typography>
            <Typography
              variant="h6"
              style={{ color: "#fff", fontWeight: "400" }}
            >
              SPX 6900
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={mainContainer}
        style={{ borderTop: "1px solid gray" }}
      >
        <Box className="dispalySpacebetween" my={1}>
          <Box>
            <Typography
              variant="body2"
              className="amountText"
              style={{ color: "rgba(255, 255, 255, 0.75)" }}
            >
              Amout:
            </Typography>
            <Typography variant="h5" mt={1}>
              2.4949999
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className="amountText">
              Bought Price:
            </Typography>
            <Typography variant="h5" mt={1}>
              1.494
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            variant="body2"
            className="amountText"
            style={{ color: "rgba(255, 255, 255, 0.75)" }}
          >
            Contract Address:
          </Typography>

          <Typography
            mt={1}
            variant="body2"
            className="amountText"
            style={{ color: "#fff" }}
          >
            0xa7a77deacc6fd3673ff23111922136eeb{" "}
            <span style={{ color: "#3F9BFF", cursor: "pointer" }}>Copy</span>
          </Typography>
        </Box>

        <Box className="displayStart" mt={1}>
          <Typography variant="body2" className="amountText">
            PNL: &nbsp;&nbsp;
          </Typography>
          <Typography
            variant="body2"
            className="amountText"
            style={{ color: "#fff" }}
          >
            +20%
          </Typography>
        </Box>
        <Box className="dispalySpacebetween" my={1}>
          <Typography variant="body2" className="amountText">
            Buying Time:
          </Typography>
          <Typography variant="body2" className="amountText">
            Holding Period:
          </Typography>
        </Box>

        <Box className="dispalySpacebetween" mt={2}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={shareBtn}
            onClick={() => navigate("/alpha-scans")}
          >
            Alpha
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={shareBtn}
            onClick={() => navigate("/Chart")}
          >
            Chart
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/share")}
            sx={shareBtn}
            style={{ minWidth: "50px", width: "24px", height: "39px" }}
          >
            <img src="/images/statics/share.png" alt="ddd" />
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
