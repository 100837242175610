import React, { useEffect, useState } from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "./commonbackbutton/BackButton";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { sortAddress } from "src/utils";

export default function CommonCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const chatIdd = window.localStorage.getItem("_chId");
  const title = location.state.title;
  const generateWalletApi = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.generateWallet,
        bodyData: {
          chatId: chatIdd,
        },
      });
      if (res.data) {
        toast.success(
          `Your new wallet with the address ${sortAddress(
            res?.data?.activeWallet?.walletAddress
          )} has been created`
        );
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    const dataList = location.state.data;

    if (dataList) {
      setListData(dataList);
    }
  }, [location]);

  return (
    <Box style={{ position: "relative", height: "94vh" }}>
      <Container>
        <Box
          className="bg_common border_radius_10"
          style={{
            position: "absolute",
            width: "100%",
            right: "0px",
            bottom: "0px",
          }}
        >
          <BackButton title={title} />
          <Box className="walletnewcard">
            <Grid container spacing={0} style={{ marginBottom: "10px" }}>
              {listData &&
                listData.map((item, index, items) => {
                  return (
                    <Grid item xs={12} key={`card${index}`}>
                      <Box
                        className={`display_center walletborder ${
                          index === items.length - 1 ? "lastItem" : ""
                        }`}
                        onClick={
                          item?.name === "Genrate Wallet"
                            ? () => {
                                generateWalletApi();
                              }
                            : () => navigate(item.href, { state: item.type })
                        }
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          className="display_center"
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          {console.log(" ------ item?.name ", item?.name)}
                          <Typography
                            variant="h6"
                            style={{
                              color: "rgba(255, 255, 255, 0.87)",
                              paddingRight: "10px",
                              fontSize: "14px",
                            }}
                          >
                            {item.name}
                          </Typography>
                          <img
                            src={item.image}
                            alt={item.name}
                            style={{ height: "25px", width: "22px" }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
