import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonDivider from "../CommonDivider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const mainContainer = {
  "& span": {
    color: "#FFF",
    fontSize: "12px",
    display: "block",
  },
  "& .ttt": {
    color: "#fff",
    textAlign: "start",
    fontSize: "12px",
  },
};
export default function DesAccordian({ type, walletData, sellOrBuy }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Accordion
        sx={{
          backgroundColor: "transparent",
          border: "none",
          margin: "10px 0px",
        }}
        expanded={expanded}
        onChange={() => setExpanded(expanded ? false : true)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
            },
          }}
        >
          <Box sx={mainContainer}>
            <Typography variant="subTitle1">
              {" "}
              {type === "step1" ? "Token Address details" : "Token balance"}:
            </Typography>
          </Box>
        </AccordionSummary>
        <CommonDivider />
        {type === "step1" && (
          <AccordionDetails sx={mainContainer}>
            <CommonText title="From:" className="ttt" />
            <CommonText
              title="CA: LP: 0x7A47D6E761643F2d11A4887 feDb80Fd068B8a977 V2 Pool"
              className="ttt"
            />
            <CommonText title="To:" className="ttt" />
            <CommonText
              title="LP: LP: 0x7A47D6E761643F2d11A4887 feDb80Fd068B8a977 V2 Pool"
              className="ttt"
            />
            <CommonText title="TXN Hash:" className="ttt" />
            <CommonText
              title="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a"
              className="ttt"
            />
            <CommonText
              title=" document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."
              className="ttt"
            />
          </AccordionDetails>
        )}
        {type === "step2" && (
          <AccordionDetails>
            <Box className="border_radius_10">
              <Box>
                <Typography style={{ fontSize: "12px", marginBottom: "8px" }}>
                  Current Value:
                </Typography>
                <Typography
                  /* className="textLinearGradient" */ style={{
                    fontSize: "22px",
                  }}
                >
                  {walletData?.tokenBalance
                    ? parseFloat(walletData?.tokenBalance).toFixed(4)
                    : "0"}
                  {walletData && walletData?.tokenID}
                </Typography>
                {walletData && walletData?.tokenBalance === 0 && sellOrBuy && (
                  <Box position="relative">
                    <img
                      style={{ position: "absolute", left: "0px", top: "3px" }}
                      src="/images/buy-sell/desicon.svg"
                      alt=""
                    />
                    <Typography
                      style={{
                        color: "#FF7A00",
                        fontFamily: "Gilroy-Medium",
                        paddingLeft: "22px",
                      }}
                    >
                      You don’t have enough token balance to sell the token. if
                      you want to buy please click on the button.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
const CommonText = ({ title, className }) => {
  return (
    <Typography
      style={{
        display: "block",
        wordBreak: "break-word",
        paddingBottom: "7px",
      }}
      className={className && className}
      variant="subTitle1"
    >
      {title}
    </Typography>
  );
};
