import { Box } from '@mui/material'
import React from 'react'
import ActiveTrade from './ActiveTrade'
import BackButton from './commonbackbutton/BackButton'

const ActiveTradePage = () => {
  return (
  <>
  <Box>
    <BackButton title="Active Trade"/>
  </Box>
  <ActiveTrade/>
  </>
  )
}

export default ActiveTradePage