import { Typography, styled } from "@mui/material";
import React from "react";
import Slider from "@mui/material/Slider";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "transparent" : "transparent",
  height: 0,
  padding: "0px !important",
  "& .MuiSlider-thumb": {
    height: 12,
    width: 12,
    backgroundColor: "transparent",
    top: "-9px",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
    },
    "&::after": {
      borderWidth: "9px",
      borderStyle: "solid",
      borderColor: "transparent #10A1FD transparent transparent",
      content: "' '",
      width: "0",
      height: "0",
      transform: "rotate(269deg)",
      borderRadius: "0px",
      top: "2px",
      left: "-2px",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: "#fff",
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: "#fff",
    },
  },
  "& .MuiSlider-track": {
    height: "0px",
  },
  "& .MuiSlider-rail": {
    height: "0px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const Widget = styled("Box")(({ theme }) => ({
  "& .slider": {
    color: theme.palette.primary.main,
    padding: "0px",
  },
  "& .root": {
    padding: "0 15px 0 10px",
  },
  "& .thumb": {
    "&::after": {
      borderWidth: "9px",
      borderStyle: "solid",
      borderColor: "transparent #075f12 transparent transparent",
      content: "' '",
      width: "0",
      height: "0",
      transform: "rotate(269deg)",
      borderRadius: "0px",
      top: "2px",
      left: "-2px",
    },
  },
  "& .rail": {
    height: "0px",
  },
  "& .track": {
    height: "0px",
  },
  "& .valueText": {
    marginTop: theme.spacing(1),
    color: "transparent",
  },

  "& .ruler": {
    position: "relative",
    width: "100%",
    height: 40,
    borderRadius: 4,
    marginTop: "-4px",
  },
  "& .marker": {
    position: "absolute",
    width: 1,
    height: 10,
    backgroundColor: theme.palette.grey[600],
  },
  "& .label": {
    position: "absolute",
    top: 20,
    transform: "translateX(-50%)",
    fontSize: "11px",
  },
}));

export default function CommonLeverageSlider1({
  setAmount,
  amount,
  walletData,
  type,
}) {
  // console.log("ammount...",typeof(parseFloat(amount)))
  const handleChange = (newValue) => {
    if (!isNaN(newValue)) {
      setAmount((parseFloat(newValue) / 100) * parseFloat(walletData));
    } else {
      setAmount(0);
    }
  };
  return (
    <Widget>
      <div className="root">
        <Typography variant="body1" className="valueText">
          {amount}
        </Typography>
        <IOSSlider
          aria-label="ios slider"
          valueLabelDisplay="on"
          value={
            parseFloat(amount) === 0 ? 0 : (amount ? (parseFloat(amount) * 100) / parseFloat(walletData) : 0)
          }
          onChange={(event, newValue) => handleChange(newValue)}
        />

        <div className="ruler">
          {[...Array(101).keys()].map((mark) => (
            <React.Fragment key={mark}>
              <div
                className="marker"
                style={{
                  left: `${(mark / 100) * 100}%`,
                  height: mark % 10 === 0 ? 10 : 5,
                }}
              />
              {mark % 10 === 0 && (
                <Typography
                  variant="body2"
                  className="label"
                  style={{ left: `${(mark / 100) * 100}%` }}
                >
                  {mark}
                </Typography>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>{" "}
    </Widget>
  );
}
