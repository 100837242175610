import React, { createContext, useEffect, useState } from "react";
import AppContext from "./AppContext";
import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import axios from "axios";
export const AccountContext = createContext();

export default function ContextWrapper({ children }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [isDataLoading, setDataLoading] = useState(true);
  const [walletData, setWalletData] = useState({});
  const [tokenName, setTokenName] = useState("");
  console.log("tokenNametokenName", tokenName);
  const [balanceData, setBalanceData] = useState({});
  console.log("balanceDatabalanceData", balanceData);
  const [topInfluencer, setTopInfluencer] = useState({});
  const [topSeries, setTopSeries] = useState({});
  const [changeInterval, setChangeInterval] = useState({});
  let chatIddd = window.localStorage.getItem("_chId");
  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";
  const [trxUsdtPrice, setTrxUsdtPrice] = useState([]);
  console.log("trxUsdtPricetrxUsdtPrice", trxUsdtPrice);

  const getCurentWalletHandler = async () => {
    try {
      let res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.currentWallet,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
        },
      });

      if (res.data) {
        setWalletData(res.data);
      } else {
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
    }
  };
  const getBalanceHandler = async () => {
    try {
      let res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.getEthBalance,
        bodyData: {
          chatId: chatIddd,
          active_chain: connectChain,
        },
      });

      if (res.data) {
        setBalanceData(res.data);
      } else {
        setIsUpdating(false);
      }
    } catch (error) {
      setIsUpdating(false);
    }
  };
  const getLivePriceCoin = async (token) => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD&api_key='fab4eccf036f6b870af1c13ae652b23259186cb5ef0ce00ae62df236ba42d719'",
      });
      console.log("responseresponse", response);
      if (response.status === 200) {
        setTrxUsdtPrice(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAlphaScanHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getTokenInfo + tokenName.toLowerCase(),
      });
      if (res.data) {
        setDataLoading(false);
        setTopInfluencer(res.data.top_influencer);
        setTopSeries(res.data.tokenseries);
        setChangeInterval(res.data.changeInterval);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getCurentWalletHandler();
    getBalanceHandler();
    getLivePriceCoin();
  }, []);

  useEffect(() => {
    getAlphaScanHandler();
  }, [tokenName]);

  let data = {
    isUpdating,
    walletData,
    balanceData,
    trxUsdtPrice,
    topInfluencer,
    topSeries,
    changeInterval,
    tokenName,
    setTokenName,
    getBalanceHandler: getBalanceHandler,
  };

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
}
