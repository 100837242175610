import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import BackButton from "../commonbackbutton/BackButton";
import PercentIcon from "@mui/icons-material/Percent";
import AddIcon from "@mui/icons-material/Add";
export default function Setting() {
  const [inputAddress, setInputAddress] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const navigate = useNavigate();
  return (
    <Box className="commanbackground">
      <BackButton title="Setting" />
      <Box
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
        }}
      ></Box>
      <Container>
        <Box>
          <Typography variant="body2" mt={2} mb={1}>
            Amount of Liquidity
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Enter amount of liquidity"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>

        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Maximum Tax of Token
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Enter max tax of token"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Liquidity Lock
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Enter liquidity lock details"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Number of Holders
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Enter number of holders"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Minimum Market Cap
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Enter minimum market cap"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Maximum Market Cap
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Enter maximum market cap "
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Maximum Tax of Token
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="Please enter amount of profit to attain"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box
          mt={3}
          className="displaySpacebetween"
          sx={{
            background: "#202020",
            borderRadius: "10px",
            padding: "0px 8px",
          }}
          mb={1}
        >
          <Typography variant="body2">Blacklist</Typography>
          <Switch />
        </Box>
        <Box
          sx={{
            border: "1px solid #202020",
            borderRadius: "10px",
            padding: "5px",
          }}
          mt={2}
        >
          <Typography variant="body2" mb={1}>
            Stop Loss
          </Typography>
          <Box className="displaySpacebetween">
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#202020",
                  height: "39px",
                  paddingLeft: "6px",
                  borderRadius: "10px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "16.5px 0px",
                  fontSize: "14px",
                },
              }}
              type="text"
              placeholder="0.0"
              variant="outlined"
              name="inputAddress"
              value={inputAddress}
              onChange={(e) => {
                setInputAddress(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon style={{ color: "#fff", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#202020",
                  height: "39px",
                  paddingLeft: "6px",
                  borderRadius: "10px",

                  marginLeft: "10px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "16.5px 0px",
                  fontSize: "14px",
                },
              }}
              type="text"
              placeholder="0.0"
              variant="outlined"
              name="inputAddress"
              value={inputAddress}
              onChange={(e) => {
                setInputAddress(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon style={{ color: "#fff", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={1} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ width: "100px" }}
              variant="contained"
              color="secondary"
              endIcon={<AddIcon />}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #202020",
            borderRadius: "10px",
            padding: "5px",
          }}
          mt={2}
        >
          <Typography variant="body2" mb={1}>
            Profit
          </Typography>
          <Box className="displaySpacebetween">
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#202020",
                  height: "39px",
                  paddingLeft: "6px",
                  borderRadius: "10px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "16.5px 0px",
                  fontSize: "14px",
                },
              }}
              type="text"
              placeholder="0.0"
              variant="outlined"
              name="inputAddress"
              value={inputAddress}
              onChange={(e) => {
                setInputAddress(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon style={{ color: "#fff", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#202020",
                  height: "39px",
                  paddingLeft: "6px",
                  borderRadius: "10px",

                  marginLeft: "10px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "16.5px 0px",
                  fontSize: "14px",
                },
              }}
              type="text"
              placeholder="0.0"
              variant="outlined"
              name="inputAddress"
              value={inputAddress}
              onChange={(e) => {
                setInputAddress(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon style={{ color: "#fff", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={1} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ width: "100px" }}
              variant="contained"
              color="secondary"
              endIcon={<AddIcon />}
              onClick={() => {
                navigate("/instant-buy");
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
