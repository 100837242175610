import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Select,
  MenuItem,
} from "@mui/material";

import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";

import ButtonCircular from "./ButtonCircular";
import BackButton from "./commonbackbutton/BackButton";

export default function ProfitAttain() {
  const [isDataLoading, setDataLoading] = useState(true);
  const [isSoldLoading, setSoldLoading] = useState(true);
  const [profit, setProfit] = useState();
  const [sold, setSold] = useState();
  const [calulatedValue, setCalulatedValue] = useState();
  const [calulatedSoldValue, setCalulatedSoldValue] = useState();

  // Get current wallet start //
  const getBoughtHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.getCurrentTokenHolding,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
        },
      });

      if (res.data) {
        setDataLoading(false);
        setProfit(res.data);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  const getSoldHandler = async () => {
    try {
      setSoldLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.getTokenSold,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
        },
      });

      if (res.data) {
        setSoldLoading(false);
        setSold(res.data);
      } else {
        setSoldLoading(false);
      }
    } catch (error) {
      setSoldLoading(false);
    }
  };
  // Get current wallet end //

  useEffect(() => {
    getSoldHandler();
    getBoughtHandler();
  }, []);

  useEffect(() => {
    if (profit && !profit?.result && sold && !sold.result) {
      const balance =
        profit &&
        profit?.tokensInfo &&
        profit?.tokensInfo.map((item) => item?.tokenAmount);
      if (balance.length > 0) {
        const sumWithInitial =
          balance &&
          balance.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

        setCalulatedValue(sumWithInitial);
      }
    }
  }, [profit, sold]);
  useEffect(() => {
    if (sold) {
      const balance =
        sold &&
        sold?.tokensInfo &&
        sold?.tokensInfo.map((item) => item?.tokenAmount);
      if (balance.length > 0) {
        const sumWithInitial =
          balance &&
          balance.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );

        console.log("hfdh", sumWithInitial);
        setCalulatedSoldValue(sumWithInitial);
      }
    }
  }, [profit, sold]);
  return (
    <Box
      className="bg_common border_radius_10"
      mt={1}
      mb={1}
      style={{
        position: "absolute",
        bottom: "0px",
        right: "0px",
        width: "100%",
      }}
    >
      <Box mb={2}>
        <BackButton title="Profit Attained" />
      </Box>
      <Container>
        <Box align="left">
          <>
            {isDataLoading || isSoldLoading ? (
              <Box
                className="displayCenter"
                style={{ paddingBottom: "20px", paddingTop: "7px" }}
              >
                <ButtonCircular />
              </Box>
            ) : (
              <>
                {/* <Typography
                  style={{ paddingBottom: "20px", fontSize: "16px !important" }}
                  textAlign="left"
                  className="textLinearGradient"
                >
                  {`${
                    calulatedSoldValue &&
                    calulatedValue &&
                    (Number(calulatedSoldValue) - Number(calulatedValue)) /
                      10 ** 18
                  } ETH`}
                </Typography> */}
                <Typography
                  style={{ paddingBottom: "20px", fontSize: "16px !important" }}
                  textAlign="left"
                  className="textLinearGradient"  
                >
                  {calulatedSoldValue !== undefined && calulatedValue !== undefined && calulatedValue !== 0 && calulatedSoldValue !== 0 ? 
                    `${(Number(calulatedSoldValue) - Number(calulatedValue)) / 10 ** 18} ETH` 
                    : '0 ETH'
                  }
                </Typography>

              </>
            )}
          </>
        </Box>
      </Container>
    </Box>
  );
}
