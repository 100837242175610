import React, { useContext, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid } from "@mui/material";
import AppContext from "src/context/AppContext";

export default function DescriptionText({ alpha }) {
  const auth = useContext(AppContext);
  const { topInfluencer } = auth;

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="alphatextBox">
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
 expandIcon={
  <ExpandMoreIcon
    style={{
      color: "#fff",
      background: "#232323",
      borderRadius: "50%",
      fontSize: "15px",
    }}
  />
}          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1">Social</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" spacing={1}>
            {alpha?.info?.socials?.length > 0 ? (
              alpha.info.socials.map((social, index) => (
                <Grid item key={index}>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">{social.type}</Typography>
                    <Typography variant="body1">{social.url}</Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="body1">No socials found</Typography>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
