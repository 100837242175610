import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function ChangeInterval({ alpha }) {
  const [expanded, setExpanded] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [panel] : []);
  };

  const handleChildChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      } else {
        return prevExpanded.filter((item) => item !== panel);
      }
    });
  };

  return (
    <div className="alphatextBox">
      <Accordion
        expanded={expanded.includes("mainAccordion")}
        onChange={handleChange("mainAccordion")}
      >
        <AccordionSummary
       expandIcon={
        <ExpandMoreIcon
          style={{
            color: "#fff",
            background: "#232323",
            borderRadius: "50%",
            fontSize: "15px",
          }}
        />
      }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1">Txns</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion
            expanded={expanded.includes("h1")}
            onChange={handleChildChange("h1")}
          >
            <AccordionSummary
           expandIcon={
            <ExpandMoreIcon
              style={{
                color: "#fff",
                background: "#232323",
                borderRadius: "50%",
                fontSize: "15px",
              }}
            />
          }
              aria-controls="panel1a-content-h1"
              id="panel1a-header-h1"
            >
              <Typography variant="body1">Last 1 Hour</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {alpha?.txns?.h1 && (
                <>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Buys
                    </Typography>
                    <Typography variant="body1">{alpha.txns.h1.buys}</Typography>
                  </Box>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Sells
                    </Typography>
                    <Typography variant="body1">{alpha.txns.h1.sells}</Typography>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded.includes("h24")}
            onChange={handleChildChange("h24")}
          >
            <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "#fff",
                background: "#232323",
                borderRadius: "50%",
                fontSize: "15px",
              }}
            />
          }
              aria-controls="panel1a-content-h24"
              id="panel1a-header-h24"
            >
              <Typography variant="body1">Last 24 Hours</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {alpha?.txns?.h24 && (
                <>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Buys
                    </Typography>
                    <Typography variant="body1">{alpha.txns.h24.buys}</Typography>
                  </Box>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Sells
                    </Typography>
                    <Typography variant="body1">{alpha.txns.h24.sells}</Typography>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded.includes("h6")}
            onChange={handleChildChange("h6")}
          >
            <AccordionSummary
           expandIcon={
            <ExpandMoreIcon
              style={{
                color: "#fff",
                background: "#232323",
                borderRadius: "50%",
                fontSize: "15px",
              }}
            />
          }
              aria-controls="panel1a-content-h6"
              id="panel1a-header-h6"
            >
              <Typography variant="body1">Last 6 Hours</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {alpha?.txns?.h6 && (
                <>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Buys
                    </Typography>
                    <Typography variant="body1">{alpha.txns.h6.buys}</Typography>
                  </Box>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Sells
                    </Typography>
                    <Typography variant="body1">{alpha.txns.h6.sells}</Typography>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded.includes("m5")}
            onChange={handleChildChange("m5")}
          >
            <AccordionSummary
   expandIcon={
    <ExpandMoreIcon
      style={{
        color: "#fff",
        background: "#232323",
        borderRadius: "50%",
        fontSize: "15px",
      }}
    />
  }
              aria-controls="panel1a-content-m5"
              id="panel1a-header-m5"
            >
              <Typography variant="body1">Last 5 Minutes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {alpha?.txns?.m5 && (
                <>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Buys
                    </Typography>
                    <Typography variant="body1">{alpha.txns.m5.buys}</Typography>
                  </Box>
                  <Box className="displaySpacebetween" mt={1} mb={1}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Sells
                    </Typography>
                    <Typography variant="body1">{alpha.txns.m5.sells}</Typography>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
