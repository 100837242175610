import React, { useRef } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import BackButton from "src/Components/commonbackbutton/BackButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
const buyingData = [
  {
    name: "Buying Time",
    price: "+100%",
  },
  {
    name: "Exit Price",
    price: "354.222",
  },
  {
    name: "Exit Price",
    price: "354.222",
  },
  {
    name: "Entry Price",
    price: "+100%",
  },
  {
    name: "Amount Earned",
    price: "354.222",
  },
  {
    name: "Duration",
    price: "354.222",
  },
];

export default function SoldDetails() {
  const contentRef = useRef(null);
  const handleDownload = () => {
    // const textElements = [
    //   ...document.querySelectorAll(".MuiTypography-root"),
    //   ...document.querySelectorAll("span"),
    // ];
  
    // const textColorElements = textElements.filter((element) => {
    //   const computedStyle = window.getComputedStyle(element);
    //   return (
    //     computedStyle.color === "rgb(255, 255, 255)" ||
    //     computedStyle.color === "#fff"
    //   );
    // });
  
    // textColorElements.forEach((element) => {
    //   element.style.color = "#000";
    // });
  
    // const textColorElements1 = textElements.filter((element) => {
    //   return element.classList.contains("custom-color");
    // });
  
    // const textColorElement2 = textElements.filter((element) => {
    //   return element.classList.contains("custom-color1");
    // });
  
    // textColorElements1.forEach((element) => {
    //   element.style.color = "#000";
    // });
  
    // textColorElement2.forEach((element) => {
    //   element.style.color = "#000";
    // });
  
    html2canvas(contentRef.current, {
      scrollY: -window.scrollY,
      windowHeight: window.innerHeight,
      backgroundColor: "rgba(5, 5, 5, 0.94)",
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
  
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      const timeStamp = new Date().toISOString().split('T')[0];

      const fileName = `sold_details_${timeStamp}.pdf`;

      pdf.save(fileName);
  
      // Reset text colors after downloading
      // textColorElements.forEach((element) => {
      //   element.style.color = "#fff";
      // });
      // textColorElements1.forEach((element) => {
      //   element.style.color = "rgba(255, 255, 255, 0.60)";
      // });
      // textColorElement2.forEach((element) => {
      //   element.style.color = "rgba(255, 255, 255, 0.60)";
      // });
    });
  };


  return (
    <>
      <Box>
        <BackButton title="Toggle and Sort" />
        <Box sx={{ width: "100%", position: "relative" }}>
          <IconButton
            color="primary"
            onClick={handleDownload}
            sx={{ position: "absolute", right: 4 }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </Box>
        <Container ref={contentRef}>
          <Box align="center">
            <img
              src="images/sold.png"
              alt="Sold"
              style={{ width: "auto", maxWidth: "100%" }}
            />
            <Typography variant="h4" color="#fff" mt={2}>
              SolUSDT
            </Typography>
            <Typography variant="body2" color="#2EC085" mt={1} mb={1}>
              Long
            </Typography>
            <Typography
              variant="h3"
              color="#2EC085"
              style={{ fontSize: "30px" }}
            >
              +259.4949
            </Typography>
          </Box>
          <Grid container spacing={1} justifyContent="center">
            {buyingData.map((data, index) => (
              <Grid item xs={4} align="center" mb={1} key={index}>
                <Typography
                  variant="body2"
                  color="rgba(255, 255, 255, 0.75)"
                  className="custom-color1"
                  mt={2}
                  style={{ fontSize: "12px" }}
                >
                  {data.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="#fff"
                  style={{ fontWeight: "400" }}
                  mt={1}
                >
                  {data.price}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1} justifyContent="center" mt={3}>
            <Grid item xs={7} align="left" mb={1}>
              <Box className="displayStart">
                <img
                  src="images/soldprofile.png"
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                />
                <Box ml={2}>
                  <Typography variant="h3" color="#fff">
                    Andor Fi
                  </Typography>
                  <Typography
                    variant="body2"
                    color="rgba(255, 255, 255, 0.60)"
                    className="custom-color"
                  >
                    bot
                  </Typography>
                </Box>
              </Box>
              <Box className="displayStart" mt={2}>
                <Typography
                  variant="body2"
                  color="rgba(255, 255, 255, 0.60)"
                  className="custom-color"
                >
                  Referral Code
                </Typography>
                &nbsp;
                <Typography variant="body2" color="#fff">
                  535165152
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5} mb={1}>
              <img
                src="images/scanner.png"
                alt="Sold"
                style={{ width: "auto", maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
