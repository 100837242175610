import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import BackButton from "../commonbackbutton/BackButton";
export default function InstantBuy() {
  const [inputAddress, setInputAddress] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const navigate = useNavigate();
  return (
    <Box
      className="commanbackground"
      style={{
        position: "absolute",
        bottom: "0px",
        right: "0px",
        width: "100%",
      }}
    >
      <BackButton title="Instant Buy" />
      <Box
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
        }}
      ></Box>
      <Container>
        <Typography variant="body2" mt={2} mb={1}>
          Token Address
        </Typography>
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#202020",
              height: "39px",
              paddingLeft: "6px",
              borderRadius: "10px",
            },
            "& .MuiOutlinedInput-input": {
              padding: "16.5px 0px",
              fontSize: "14px",
            },
          }}
          fullWidth
          type="text"
          placeholder="0xa7a77deacf23111922136eeb49c2b12f5"
          variant="outlined"
          name="inputAddress"
          value={inputAddress}
          onChange={(e) => {
            setInputAddress(e.target.value);
          }}
        />
        <Box mt={1}>
          <Typography variant="body2" mt={2} mb={1}>
            Amount
          </Typography>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            fullWidth
            type="text"
            placeholder="0xa7a77deacf23111922136eeb49c2b12f5"
            variant="outlined"
            name="inputAddress"
            value={inputAddress}
            onChange={(e) => {
              setInputAddress(e.target.value);
            }}
          />
        </Box>
        <Box mt={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/setting");
            }}
          >
            Buy
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
