import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const iconButton = {
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  "& .MuiIconButton-root": {
    padding: "2px",
    height: "20px",
    width: "20px",
    background: "#232323",
  },
};
const iconButton1 = {
  display: "flex",
  justifyContent: "space-between",
  "& .MuiIconButton-root": {
    padding: "2px",
    height: "20px",
    width: "20px",
    background: "#232323",
  },
};
export default function BackButton({ title, type }) {
  const navigate = useNavigate();

  return (
    <Box sx={type === "FILTER" ? iconButton1 : iconButton} mt={1}>
      <Typography sx={type === "FILTER" ? { marginLeft: "-7px" } : {}}>
        {title}
      </Typography>
      <IconButton onClick={() => navigate(-1)}>
        <IoMdArrowRoundBack />
      </IconButton>
    </Box>
  );
}
