import { React, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sortAddress } from "src/utils";
import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const mainContainer = {
  "& .MuiAccordionSummary-content": {
    display: "block",
  },
  "& span": {
    color: "#FFF",
    fontSize: "12px",
    whiteSpace: "pre",
  },
  "& .ttt": {
    color: "#fff",
    textAlign: "start",
    fontSize: "12px",
  },
  "& .buttonBox": {
    "& .alphaBtn": {
      width: "130px",
    },
    "& .ImgBtn": {
      height: "37px",
      width: "20px",
    },
  },
  padding: "0px",
  "& .Mui-expanded": {
    minHeight: "40px",
  },

  "& h5": {
    color: "#FFF",
  },
  "& .amountText": {
    color: "rgba(255, 255, 255, 0.75)",
    fontSize: "12px",
  },
};
const shareBtn = {
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.12)",
};
const descriptionText = {
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.12)",
};

export default function AccordianCommon({
  item,
  index,
  expanded,
  handleChange,
}) {
  const [inputData, setInputData] = useState([]);
  console.log("inputDatathjdyhryehryeh5ye.....", item);
  const [dataLoading, setDataLoading] = useState(true);
  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";
  const navigate = useNavigate();
  const getInputDataApi = async (input, functionname) => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.getInputData,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
          active_chain: connectChain,
          inputData: input ? input : null,
          functionName: functionname ? functionname : null,
        },
      });
      console.log("resres", res);

      if (res.data) {
        setInputData(res?.data);

        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  function calculateHoldingPeriod(created_at) {
    const createdAtDate = new Date(created_at);
    const currentDate = new Date();
    const difference = createdAtDate - currentDate;
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} hrs ${minutes} mins`;
  }
  return (
    <Accordion
      key={`transaction${index}`}
      sx={{
        background: "#202020",
        border: "none",
        margin: "3px 0px",
        padding: "10px",
        borderRadius: "10px 10px 0 0px !important",
      }}
      expanded={expanded === index}
      onChange={handleChange(index)}
    >
      <AccordionSummary
        style={{ justifyContent: "space-between" }}
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: "#fff",
              background: "#2B2B2B",
              borderRadius: "50%",
            }}
            onClick={() => {
              getInputDataApi(item?.input, item?.functionName);
            }}
          />
        }
        aria-controls={item}
        id={item}
      >
        <Box style={{ width: "100%" }} mr={1}>
          <Box className="displaySpacebetween" my={1}>
            <Box>
              <Typography
                variant="subTitle1"
                style={{ color: "rgba(255, 255, 255, 0.75)" }}
              >
                Token:
              </Typography>
              &nbsp;&nbsp;
              <Typography variant="subTitle1">
                {item?.tokenName ? item?.tokenName : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subTitle1"
                style={{ color: "rgba(255, 255, 255, 0.75)" }}
              >
                Time:
              </Typography>{" "}
              &nbsp;&nbsp;
              <Typography variant="subTitle1">
                {item?.created_at
                  ? new Date(item?.created_at).toLocaleString("en-US", {
                      timeZone: "UTC",
                      timeStyle: "short",
                    }) +
                    " " +
                    new Date(item?.created_at).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })
                  : "--"}
              </Typography>
            </Box>
          </Box>

          <Box className="displaySpacebetween" my={1}>
            <Box>
              <Typography
                className="ttt"
                variant="subTitle1"
                style={{ color: "rgba(255, 255, 255, 0.75)" }}
              >
                Amount : &nbsp;&nbsp;
                <Typography variant="subTitle1">
                  {item?.tokenAmount
                    ? `${Number(item?.tokenAmount)} ETH`
                    : "N/A"}
                </Typography>
              </Typography>
            </Box>
          </Box>

          <Box className="displaySpacebetween" my={1}>
            <Box style={{ width: "100%" }}>
              <Typography
                className="ttt"
                variant="subTitle1"
                style={{ color: "rgba(255, 255, 255, 0.75)" }}
              >
                Type: &nbsp;&nbsp;
                <Typography variant="subTitle1" sx={{ descriptionText }}>
                  {item?.type ? item?.type : "N/A"}
                </Typography>
              </Typography>
            </Box>

            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate("/share")}
                sx={{
                  borderRadius: "18px",
                  border: "0.618px solid rgba(255, 255, 255, 0.08)",
                  background: "rgba(255, 255, 255, 0.02)",
                }}
                endIcon={<img src="/images/statics/send.svg" alt="ddd" />}
              >
                Share
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={mainContainer}
        style={{ borderTop: "1px solid gray" }}
      >
        <Box className="dispalySpacebetween" my={1}>
          <Box>
            <Typography
              variant="body2"
              className="amountText"
              style={{ color: "rgba(255, 255, 255, 0.75)" }}
            >
              Token ID:
            </Typography>
            <Typography variant="h5" mt={1}>
              {item?.tokenSymbol ? item?.tokenSymbol : "N/A"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className="amountText">
              Bought Price:
            </Typography>
            <Typography variant="h5" mt={1}>
              {item?.tokenAmount ? item?.tokenAmount : "N/A"}
            </Typography>
          </Box>
        </Box>
        <Box className="dispalySpacebetween" my={1}>
          <Box>
            <Typography
              variant="body2"
              className="amountText"
              style={{ color: "rgba(255, 255, 255, 0.75)" }}
            >
              Amount:
            </Typography>
            <Typography variant="h5" mt={1}>
              {item?.tokenAmount
                ? `${item.tokenAmount} ${
                    item.active_chain === "bsc" ? "bnb" : item.active_chain
                  }`
                : "0"}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            variant="body2"
            className="amountText"
            style={{ color: "rgba(255, 255, 255, 0.75)" }}
          >
            Contract Address:
          </Typography>
          <CopyToClipboard
            text={item && item?.token_address && item?.token_address}
          >
            <Typography
              mt={1}
              variant="body2"
              className="amountText"
              style={{ color: "#fff" }}
            >
              {item?.token_address ? sortAddress(item?.token_address) : "N/A"}
              <span
                style={{ color: "#3F9BFF", cursor: "pointer" }}
                onClick={() => toast.success("Copied")}
              >
                {" "}
                Copy
              </span>
            </Typography>
          </CopyToClipboard>
        </Box>

        {/* <Box className="displayStart" mt={1}>
          <Typography variant="body2" className="amountText">
            PNL: &nbsp;&nbsp;
          </Typography>
          <Typography
            variant="body2"
            className="amountText"
            style={{ color: "#fff" }}
          >
            +20%
          </Typography>
        </Box> */}
        <Box className="dispalySpacebetween" my={1}>
          <Box>
            <Typography variant="body2" className="amountText">
              Buying Time: &nbsp;&nbsp;
            </Typography>
            <Typography
              variant="body2"
              className="amountText"
              style={{ color: "#fff" }}
            >
              {item?.created_at
                ? new Date(item?.created_at).toLocaleString("en-US", {
                    timeZone: "UTC",
                    timeStyle: "short",
                  }) +
                  " " +
                  new Date(item?.created_at).toLocaleDateString("en-US", {
                    timeZone: "UTC",
                  })
                : "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" className="amountText">
              Holding Period:&nbsp;&nbsp;
            </Typography>
            <Typography
              variant="body2"
              className="amountText"
              style={{ color: "#fff" }}
            >
              {item?.created_at
                ? calculateHoldingPeriod(item.created_at)
                : "N/A"}
            </Typography>
          </Box>
        </Box>

        <Box className="dispalySpacebetween" mt={2}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={shareBtn}
            onClick={() => navigate("/alpha-scans")}
          >
            Alpha
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={shareBtn}
            onClick={() => navigate("/Chart")}
          >
            Chart
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/share")}
            sx={shareBtn}
            style={{ minWidth: "50px", width: "24px", height: "39px" }}
          >
            <img src="/images/statics/share.png" alt="ddd" />
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
