import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import BackButton from "../commonbackbutton/BackButton";
import { toast } from "react-toastify";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import ButtonCircular from "../ButtonCircular";

import { FormHelperText, TextField } from "@mui/material";
import CommonTextField from "../CommonField/CommonTextField";
export default function SnipingCard() {
  const location = useLocation();
  const type = location.state;
  const [isDataLoading, setDataLoading] = useState(true);
  const [walletData, setWalletData] = useState();
  const [startleverage, setstartleverage] = useState("");
  const [isSubmit, setisSubmit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
  });

  let chatIddd = window.localStorage.getItem("_chId");
  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";
  const getCurentWalletHandler = async () => {
    try {
      setDataLoading(true);
      let res;
      if (type === "deposit") {
        let Ares = await apiRouterCall({
          method: "POST",
          url: ApiConfig.currentWallet,
          bodyData: {
            chatId: window.localStorage.getItem("_chId"),
          },
        });
        // console.log(" ---- res.data ", Ares.data);
        res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.walletToDeposit,
          // chatId: window.localStorage.getItem("_chId"),
          bodyData: {
            chatId: window.localStorage.getItem("_chId"),
            privateKey: Ares.data.privateKey,
            publicKey: Ares.data.publicKey,
          },
        });
      } else {
        res = await apiRouterCall({
          method: "POST",
          url:
            type === "leverageAttain"
              ? ApiConfig.getCurrentLeverage
              : type === "trustScore"
              ? ApiConfig.getHealthFactor
              : type === "laverage"
              ? ApiConfig.getLeverage
              : type === "deposit"
              ? ApiConfig.walletToDeposit
              : ApiConfig.getLiquidationThreshold,
          bodyData: {
            chatId: window.localStorage.getItem("_chId"),
          },
        });
      }
      console.log(" --- res.data ", res.data);
      if (res.data) {
        setDataLoading(false);
        setWalletData(res.data);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    const type = location.state;
    if (
      type === "deposit" ||
      type === "threshold" ||
      type === "laverage" ||
      type === "trustScore" ||
      type === "leverageAttain"
    ) {
      getCurentWalletHandler();
    }
  }, [location]);

  const startStopLeverage = async (type) => {
    try {
      let data1;
      if (type === "start") {
        if (formData.amount == "") {
          setisSubmit(true);
          return;
        }
        setisSubmit(false);
        data1 = {
          chatId: chatIddd,
          amount: formData.amount,
          active_chain: connectChain,
        };
      } else {
        data1 = {
          chatId: chatIddd,
        };
      }
      const res = await apiRouterCall({
        method: "POST",
        url:
          type === "start"
            ? ApiConfig.startWithLeverage
            : ApiConfig.stopLeverageSniping,
        bodyData: data1,
      });
      if (res.data) {
        setstartleverage(false);
        setFormData({
          ...formData,
          ["amount"]: "",
        });
        toast.success(type === "start" ? "Leverage Started" : "Leverage Stop");
      }
    } catch (error) {}
  };

  return (
    <>
      <Box mb={2}>
        <BackButton
          title={
            type === "leverageAttain"
              ? "Leverage Attained"
              : type === "laverage"
              ? "Start Leverage"
              : type === "trustScore"
              ? "Trust Score"
              : type === "deposit"
              ? "Wallet to Deposit"
              : type === "threshold"
              ? "Liquidation Threshold"
              : ""
          }
        />
      </Box>
      <Container>
        <Box className="bg_common border_radius_10">
          <Box
            className="walletContainer displayCenter"
            style={{ height: "157px", textAlign: "center" }}
          >
            <Box>
              {type === "laverage" && (
                <>
                  {isDataLoading ? (
                    <ButtonCircular />
                  ) : (
                    <>
                      {walletData?.leverageValue ? (
                        <>
                          <Typography>
                            {" "}
                            {walletData && walletData?.leverageValue}
                          </Typography>
                          {!startleverage ? (
                            <Box mt={1}>
                              <Button
                                // onClick={() => startStopLeverage("start")}
                                onClick={() => setstartleverage(true)}
                                variant="contained"
                              >
                                Start With leverage
                              </Button>
                              <Box mt={1}></Box>
                              <Button
                                onClick={() => startStopLeverage("stop")}
                                variant="contained"
                              >
                                Stop with leverage
                              </Button>
                            </Box>
                          ) : (
                            <Box mt={1}>
                              {/* <CommonTextField
                                formData={formData}
                                placeHolder="Enter your amount"
                                name="amount"
                                regex=""
                                type="number"
                                setFormData={(item) => setFormData(item)}
                                setValidation={(item) => setValidation(item)}
                                errorMsg="Please enter emount"
                                errorMsg1="Please enter vailid amount"
                                isSubmit={isSubmit}
                                data={formData.amount}
                                validation={validation}
                                isLoading={isLoading}
                              /> */}
                              <TextField
                                placeholder="Enter your amount"
                                fullWidth
                                value={formData.amount ? formData.amount : ""}
                                disabled={isLoading ? isLoading : ""}
                                name="amount"
                                type="number"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    ["amount"]: e.target.value,
                                  });
                                }}
                              />
                              <FormHelperText error>
                                {isSubmit && formData.amount === ""
                                  ? "Please enter emount"
                                  : ""}
                              </FormHelperText>
                              <Box mt={1}>
                                <Button
                                  onClick={() => startStopLeverage("start")}
                                  // onClick={() => setstartleverage(true)}
                                  variant="contained"
                                >
                                  Start
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Typography>
                          {walletData && !walletData.leverageValue
                            ? walletData.result
                            : "Data not found"}
                          {walletData.value && walletData.value}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
              <Typography variant="h6">
                {type === "deposit" ? "Wallet to Deposit" : ""}
              </Typography>
              {type === "deposit" && (
                <>
                  {isDataLoading ? (
                    <ButtonCircular />
                  ) : (
                    <>
                      {walletData && walletData.wallet ? (
                        <>
                          <Typography
                            variant="body2"
                            style={{
                              width: "255px",
                              wordBreak: "break-word",
                              paddingTop: "9px",
                              paddingBottom: "10px",
                            }}
                          >
                            {walletData && walletData?.wallet}
                          </Typography>
                          <CopyToClipboard
                            text={walletData && walletData?.wallet}
                          >
                            <Button
                              onClick={() => toast.success("Copied")}
                              style={{
                                background: "#222E3F",
                                fontSize: "12px",
                                padding: "6px 10px",
                              }}
                              startIcon={
                                <img src="images/copy-icon.svg" alt="" />
                              }
                            >
                              Copy
                            </Button>
                          </CopyToClipboard>
                        </>
                      ) : (
                        <Typography>
                          {walletData && !walletData.wallet
                            ? walletData.result
                            : "Wallet not"}{" "}
                          found
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
              {(type === "trustScore" ||
                type === "threshold" ||
                type === "leverageAttain") && (
                <>
                  <Typography
                    variant="body2"
                    style={{
                      width: "255px",
                      wordBreak: "break-word",
                      paddingTop: "9px",
                      paddingBottom: "10px",
                    }}
                  >
                    {type === "trustScore"
                      ? "The current trust score of your wallet is"
                      : "The current liquidation threshold is"}
                  </Typography>
                  {isDataLoading ? (
                    <Box
                      className="displayCenter textLinearGradient"
                      style={{ paddingBottom: "50px", paddingTop: "7px" }}
                    >
                      <ButtonCircular />
                    </Box>
                  ) : (
                    <>
                      {walletData &&
                      !walletData?.liqThres &&
                      !walletData?.liqThres &&
                      !walletData?.healthFactor &&
                      !walletData?.healthFactor &&
                      !walletData?.leverageMultiplier &&
                      !walletData?.leverageMultiplier &&
                      walletData.result ? (
                        <Typography className="textLinearGradient">
                          {walletData.result}
                        </Typography>
                      ) : (
                        <Typography className="textLinearGradient">
                          {walletData && walletData?.liqThres
                            ? walletData?.liqThres
                            : walletData?.healthFactor
                            ? walletData?.healthFactor
                            : walletData?.leverageMultiplier
                            ? walletData?.leverageMultiplier
                            : ""}
                          {type === "trustScore" &&
                          walletData?.healthFactor == 0
                            ? "0"
                            : ""}
                          {type === "leverageAttain" &&
                          walletData?.leverageMultiplier == 0
                            ? "0"
                            : ""}
                          {type === "threshold" && walletData?.healthFactor == 0
                            ? "0"
                            : ""}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
