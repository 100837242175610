import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  IconButton,
  FormHelperText,
} from "@mui/material";
import DescriptionText from "./DescriptionText";
import ChangeInterval from "./ChangeInterval";
import TokenSeries from "./TokenSeries";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import AppContext from "src/context/AppContext";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import useDebounce from "src/Components/Debounce";
import CommonDivider from "src/Components/CommonDivider";
import ListLoder from "src/Components/Skeletons/ListLoder";
import { toast } from "react-toastify"; // Import the toast module

export default function AlphaScans({ checkBalance, setCheckBalance }) {
  console.log("checkBalancecheckBalance", checkBalance);
  const auth = useContext(AppContext);
  const { setTokenName } = auth;
  const [isDataLoading, setDataLoading] = useState(false);
  const [validation, setValidation] = useState(true);
  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";
  const [tokenData, setTokenData] = useState({});
  const [tokenAddress, setTokenAddress] = useState();
  const debounce = useDebounce(tokenAddress, 2000);
  const [isListGetBalanceError, setIsListGetBalanceError] = useState(false);

  const navigate = useNavigate();
  const [alpha, setAlpha] = useState([]);
  const regex = /^0x[0-9A-Fa-f]{40}$/;

  const getAlphaScanData = async (tokenAddress, connectChain) => {
    try {
      setDataLoading(true); 
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.alphaScan,
        bodyData: {
          token_address: tokenAddress,
          active_chain: connectChain,
        },
      });
  
      setDataLoading(false);
  
      if (res.status === 400 || res.status !== 200) {
        setValidation(true);
        setIsListGetBalanceError(false);
        toast.error( "Kindly Check Your Token Address ", {
          style: {
            background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
            color: "#fff",
          },
        });
      } else {
        if (res.data) {
          setAlpha(res.data);
          setIsListGetBalanceError(true);
          setValidation(true);
          toast.success("AlphaScan data fetched successfully", {
            style: {
              background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
              color: "#fff",
            },
          });
        } else {
          setValidation(true);
          setIsListGetBalanceError(false);
          toast.error("Kindly Check Your Token Address ", {
            style: {
              background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
              color: "#fff",
            },
          });
        }
      }
    } catch (error) {
      setDataLoading(false);
      toast.error("Kindly Check Your Token Address ", {
        style: {
          background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
          color: "#fff",
        },
      });
    }
  };
  
  return (
    <>
      <Container>
        <Box className="displaySpacebetween" mt={1}>
          <Typography variant="h6" fontSize="16px" >Alpha Scan</Typography>
          <IconButton onClick={() => navigate(-1)}>
            <IoMdArrowRoundBack />
          </IconButton>
        </Box>

        <Box className="alphaBox">
          <Box>
            <Typography variant="body1" className="selectaccountText" mb={1}>
            Enter a token address to get more information
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              type="text"
              placeholder="Token Address"
              name="tokenAddress"
              value={tokenAddress}
              onChange={(e) => {
                if (regex.test(e.target.value)) {
                  setValidation(true);
                  getAlphaScanData(e.target.value, connectChain);
                } else {
                  setValidation(false);
                  setIsListGetBalanceError(false);
                }
                setTokenAddress(e.target.value);
              }}
            />
            <FormHelperText error>
              {tokenAddress !== "" && !validation && "Invalid token address"}
            </FormHelperText>
          </Box>
          {validation && tokenAddress !== "" && isListGetBalanceError &&(
            <>
              <Typography variant="body1" mt={1}>
                Token Address details:
              </Typography>
              <Box mt={2}>
                <CommonDivider />
              </Box>

              {isDataLoading ? (
                [1, 2, 3, 4, 5, 6].map((item, i) => {
                  return <ListLoder key={i} />;
                })
              ) : (
                <>
                  <DescriptionText alpha={alpha} />
                  <TokenSeries alpha={alpha} />
                  <ChangeInterval alpha={alpha} />
                </>
              )}

              <Box mt={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  className="chatBox"
                  fullWidth
                  onClick={() => navigate("/Chart")}
                >
                  Chart
                </Button>
              </Box>
            </>
          )}
            {isDataLoading && (
       [1, 2, 3, 4, 5, 6].map((item, i) => {
        return <ListLoder key={i} />;
      })
      )}
        </Box>
      </Container>
    </>
  );
}
