export const themeOptions = {
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: 50,
      letterSpacing: "1px",
      fontFamily: "'Sora', sans-serif",
      "@media (max-width:1024px)": {
        fontSize: "40px !important",
      },
      "@media(max-width:767px)": {
        fontSize: "30px !important",
      },
    },
    h2: {
      letterSpacing: "1px",
      fontWeight: 400,
      fontSize: 40,
      wordBreak: "break-word",
      fontFamily: "'Sora', sans-serif",
      "@media(max-width:767px)": {
        fontSize: "17px !important",
      },
    },
    h3: {
      fontWeight: 400,
      fontSize: 35,
      color: "#161E29",
      fontFamily: "'Sora', sans-serif",
      "@media(max-width:767px)": {
        fontSize: "23px",
      },
    },
    h4: {
      fontWeight: 400,
      fontSize: 30,
      fontFamily: "'Sora', sans-serif",
      "@media(max-width:767px)": {
        fontSize: "20px !important",
      },
    },
    h5: {
      fontWeight: 400,
      fontSize: 22,
      fontFamily: "'Sora', sans-serif",
      "@media(max-width:767px)": {
        fontSize: "18px !important",
      },
    },
    h6: {
      // fontFamily: "'Roboto Condensed', sans-serif",
      fontFamily: "'Sora', sans-serif",
      fontWeight: 300,
      fontSize: 14,
      color: "#fff",
    },
    body2: {
      fontFamily: "'Sora', sans-serif",
      fontSize: 14,
      fontWeight: 300,
      color: "rgba(255, 255, 255, 0.87)",
    },
    body1: {
      fontFamily: "'Sora', sans-serif",
      color: "#fff",
      fontSize: 16,
    },
    subTitle1: {
      fontFamily: "'Sora', sans-serif",
      fontWeight: 300,
      fontSize: 11,
    },
  },
};
