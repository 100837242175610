// const url = "http://172.16.2.11:8000";

// export const baseUrl = "http://172.16.2.11:8001";
// export const urlLocal = "http://172.16.2.11:5000";

// const url = "https://py-telegramjoshua.mobiloitte.io";
// export const baseUrl = "https://py-telegramjoshua.mobiloitte.io";
const url = "http://172.16.0.192:8000";
export const baseUrl = "http://172.16.0.192:8000";

const ApiConfig = {
  currentWallet: `${url}/currentWallet`,
  checkChatId: `${url}/checkChatId`,
  createChatId: `${url}/createChatId`,
  initLeverage: `${url}/initLeverage`,
  alphaScan: `${url}/alphaScan`,
  checkStatusNewSniper:`${url}/checkStatusNewSniper`,
  stopNewSniper:`${url}/stopNewSniper`,
  tokenSniper:`${url}/tokenSniper`,

  getEthBalance: `${url}/getEthBalance`, //eth buy to check balance
  getBalance: `${url}/getBalance`,
  getCurrentTokenHolding: `${url}/getCurrentTokenHolding`,
  setWallet: `${url}/setWallet`,
  walletToDeposit: `${url}/walletToDeposit`,
  getLiquidationThreshold: `${url}/getLiquidationThreshold`,
  getCurrentLeverage: `${url}/getCurrentLeverage`, //leverageto attain
  getHealthFactor: `${url}/getHealthFactor`, //trustScore
  getLeverage: `${url}/getLeverage`,
  startWithLeverage: `${url}/startWithLeverage`,
  stopLeverageSniping: `${url}/stopLeverageSniping`,
  godModeStart: `${url}/godModeStart`,
  tokenBalance: `${url}/tokenBalance`,
  getTokenSold: `${url}/getTokenSold`,
  makeContributions: `${url}/makeContributions`,
  buytokenswithtokens: `${url}/buytokenswithtokens`, //buy token
  estimatValuesForGetEth: `${url}/estimatValuesForGetEth`,
  getTokenInfo: `${url}/getTokenInfo/`,
  sellToken: `${url}/sellToken`,
  buyToken: `${url}/buyToken`,
  buyTokenWithLeverage: `${url}/buyTokenWithLeverage`,
  getLeverageWithToken: `${url}/getLeverageWithToken`,
  getLeveragetoken: `${url}/getLeveragetoken`,
  checkStatus: `${url}/checkStatus`,
  stop: `${url}/stop`,
  searchbar: `${url}/searchbar`,
  getInputData: `${url}/getInputData`,
  generateWallet: `${url}/generateWallet`,
  activeTrade: `${url}/activeTrade`,
  //Multiple Wallets
  getWallets: `${url}/getWallets`,
  changeWallet: `${url}/changeWallet`,
  activeWallet: `${url}/activeWallet`,
  addWallet: `${url}/addWallet`,

  //Mirror
  checkStatusMirror: `${url}/checkStatusMirror`,
  setMirrorWallet: `${url}/setMirrorWallet`,
  setMaxMirrorLimit: `${url}/setMaxMirrorLimit`,
  mirror: `${url}/mirror`,
  mirrorStop: `${url}/mirrorStop`,
};

export default ApiConfig;
