import React , { useState }from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { Grid, IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNavigate } from "react-router-dom";
import BackButton from "../commonbackbutton/BackButton";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? "#131313"
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#131313" : grey[800],
  width: "100%",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? "#131313" : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",

  // const iconButton = {
  //   display:"flex",
  //   justifyContent:"space-between",
  //   "& .MuiIconButton-root":{
  //     padding:"2px",
  //     height:"20px",
  //     width:"20px",
  //     background:"#232323"
  //   }
  //   };
}));
  
function SwipeableEdgeDrawer(props) {
  const navigate = useNavigate;
  const { window, open, setOpen, filterData, _onInputChange, setFilterData } =
    props;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const [fromDate, setFromDate] = useState("");
  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // Disable dates after today
  const isFutureDate = (date) => {
    return date > new Date();
  };
  const disableFutureYears = (date) => {
    return date.getFullYear() > new Date().getFullYear();
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
            width: "100%",
          },
        }}
      />

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            pt: 2,
            background: "#131313",
            borderTop: open && "1px solid gray",
            borderTopLeftRadius: open && "5px",
            borderTopRightRadius: open && "5px",
          }}
        >
          <Box mb={2}>
            <BackButton title="Filter" type="FILTER" />
          </Box>
          <Grid
            container
            spacing={1}
            style={{
              background: "#131313",
            }}
          >
            <Grid xs={12}>
              <Typography>Amount</Typography>
              <TextField
              type="number"
                placeholder="Please enter amount"
                variant="outlined"
                name="amount"
                fullWidth
                onChange={_onInputChange}
              />
            </Grid>
            <Grid xs={12}>
              <Typography style={{ paddingTop: "7px" }}>Date</Typography>
            </Grid>
            <Grid xs={6}>
              <Box mr={1}>
                <TextField
                  type="date"
                  name="fromDate"
                  variant="outlined"
                  fullWidth
                  // onChange={_onInputChange}
                  onChange={(e) => {
                    setFromDate(e.target.value); // Update fromDate state
                    _onInputChange(e); // Handle other input change logic
                  }}
                  inputProps={{ max: currentDate }}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <TextField
                  type="date"
                  name="toDate"
                  variant="outlined"
                  fullWidth
                  onChange={_onInputChange}
                  // inputProps={{ 
                  // min: fromDate, 
                  // max: currentDate  }}

                  inputProps={{ 
                    min: fromDate,
                    max: currentDate 
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box mt={1} mr={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                    setFilterData({
                      amount: "",
                      fromDate: "",
                      toDate: "",
                    });
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box mt={1}>
                <Button
                  onClick={toggleDrawer(false)}
                  variant="contained"
                  color="primary"
                >
                  Apply Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
