import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  FormHelperText,
  TextField,
  Switch,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import io from "socket.io-client";
import ApiConfig, { baseUrl } from "../../config/APICongig";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonCircular from "../ButtonCircular";
import DesAccordian from "./DesAccordian";
import { handleNegativeValue, tokenAddressRegex } from "src/utils";
import { apiRouterCall } from "src/config/service";
import { RxCross2 } from "react-icons/rx";
import { IoMdArrowRoundBack, IoMdCopy } from "react-icons/io";
import CommonLeverageSlider1 from "../leverageBuy/CommonLeverageSlider1";
import AppContext from "src/context/AppContext"

import ClipLoader from "react-spinners/ClipLoader";
const TabMainBox = styled(Box)(({ theme }) => ({
  "& .mainWalletBox": {
    "& .ButtonBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "& .butontab": {
        padding: "10px",
        cursor: "pointer",
        marginRight: "5px",
        borderRadius: "0px",
        width: "100%",
        maxWidth: "300px",
        "& h6": {
          color: "#FFFFFF",
        },
      },
      "& .activtab": {
        padding: "10px",
        cursor: "pointer",
        marginRight: "5px",
        borderRadius: "0px",
        width: "100%",
        maxWidth: "300px",
        borderBottom: "1px solid #14CBFB",
        "& h6": {
          color: "#14CBFB",
        },
      },
      "& .MuiButton-root": {
        textTransform: "capitalize",
      },
    },
  },
}));
export default function BuySection({ buyType }) {
  const [debouncedAmount, setDebouncedAmount] = useState("");
  const DEBOUNCE_DELAY = 500;
  const auth = useContext(AppContext);
  const { balanceData } = auth;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(buyType ? buyType : "");
  console.log("typetype", type);
  const [stepCount, setStepCount] = useState(0);
  const [amount, setAmount] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenToGetAddress, setTokenToGetAddress] = useState("");
  const [validation, setValidation] = useState(false);
  const [validation1, setValidation1] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [flashTab, setFlashTab] = useState("ETH");
  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedAmount(amount);
    }, DEBOUNCE_DELAY);
    return () => clearTimeout(timer);
  }, [amount]);

  useEffect(() => {
    if (debouncedAmount !== "") {
      transactionDetails();
    }
  }, [debouncedAmount]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    const dataType = buyType;

    if (dataType) {
      setType(dataType);
    }
  }, [buyType]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  // Buy token with api
  const butTokenAPIHandler = async () => {
    if (amount !== "" && tokenToGetAddress !== "" && Number(amount) !== 0) {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.buytokenswithtokens,
          bodyData: {
            tokenToSpendAddress: tokenAddress,
            tokenToGetAddress: tokenToGetAddress,
            amount: amount,
            chatId: window.localStorage.getItem("_chId"),
          },
        });
        if (res.data) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  // Buy functionality with socket
  const buyTokenHandler = async () => {
    let userId = window.localStorage.getItem("_chId");
    const socketConnected = await io.connect(baseUrl);

    if (amount !== "" && socketConnected && Number(amount) !== 0 && userId) {
      const dataToSend = {
        amount: parseFloat(amount),
        tokenAddress: tokenAddress,
        chatId: userId,
        active_chain: connectChain,
      };
      setIsLoading(true);
      // await socketConnected.emit(
      //   type === "buy" ? "buyToken" : "sellToken",
      //   dataToSend
      // );
      const res = await apiRouterCall({
        method: "POST",
        url: type === "buy" ? ApiConfig.buyToken : ApiConfig.sellToken,
        bodyData: dataToSend,
      });

      setIsSubmit(false);
      if (res?.data == 200) {
        setIsSubmit(false);
        navigate(-1);
        toast.success(
          type === "buy"
            ? "Token bought successfully"
            : "Token sell successfully"
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.error);
        console.log("res");
        setIsLoading(false);
        navigate(-1);
      }
    } else {
      toast.error("Something went wrong!");
      setIsSubmit(true);
      navigate(-1);
    }
  };

  const [walletData, setWalletData] = useState();
  const [isDataLoading, setDataLoading] = useState(true);
  const [ethData, setEthData] = useState({});
  const [isListGetBalanceError, setIsListGetBalanceError] = useState(false);

  const listGetBance = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.tokenBalance,
        bodyData: {
          tokenAddress: tokenAddress,
          chatId: window.localStorage.getItem("_chId"),
          active_chain: connectChain,
        },
      });

      if (res.status === 400) {
        // setIsListGetBalanceError(true);
        setValidation(false);
        setDataLoading(false);
        setIsListGetBalanceError(false);
      } else {
        // setIsListGetBalanceError(false);
        if (res.data) {
          setDataLoading(false);
          setIsListGetBalanceError(true);
          setValidation(true);
          setWalletData(res.data);
        } else {
          setDataLoading(false);
          setIsListGetBalanceError(false);
        }
      }
    } catch (error) {
      setDataLoading(false);
      setIsListGetBalanceError(false);
    }
  };

  const transactionDetails = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.estimatValuesForGetEth,
        bodyData: {
          fromToken: tokenAddress,
          Amount: parseFloat(amount),
          chatId: window.localStorage.getItem("_chId"),
          active_chain: connectChain,
        },
      });
      if (res?.data) {
        setDataLoading(false);
        setEthData(res?.data?.data);
        console.log("huhuhuhuhhkokokokko", ethData);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (tokenAddress && validation) {
        listGetBance();
      }
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeoutId);
  }, [tokenAddress,validation]);

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      {stepCount === 0 ? (
        <CommonStep
          title={type === "buy" ? "Instant Buy" : "Instant Sell"}
          setStepCount={(item) => setStepCount(item)}
          stepCount={stepCount}
          setTokenAddress={(item) => setTokenAddress(item)}
          tokenAddress={tokenAddress}
          setValidation={(item) => setValidation(item)}
          validation={validation}
          walletData={walletData}
          checked={checked}
          type={type}
          handleChange={handleChange}
          flashTab={flashTab}
          setFlashTab={setFlashTab}
          isListGetBalanceError={isListGetBalanceError}
          listGetBance={listGetBance}
          isDataLoading={isDataLoading}
          setDataLoading={setDataLoading}
          setIsListGetBalanceError={setIsListGetBalanceError}

        />
      ) : (
        <>
          {stepCount === 1 ? (
            <CommonStep
              title={type === "buy" ? "Instant Buy" : "Instant Sell"}
              setStepCount={(item) => setStepCount(item)}
              stepCount={stepCount}
              setTokenAddress={(item) => setTokenAddress(item)}
              tokenAddress={tokenAddress}
              setValidation={(item) => setValidation(item)}
              validation={validation}
              walletData={walletData}
              checked={checked}
              type={type}
              handleChange={handleChange}
              flashTab={flashTab}
              setFlashTab={setFlashTab}
              isListGetBalanceError={isListGetBalanceError}
              listGetBance={listGetBance}
              isDataLoading={isDataLoading}
              setDataLoading={setDataLoading}
              setIsListGetBalanceError={setIsListGetBalanceError}

            />
          ) : (
            <>
              <CommonStep
                title={type === "buy" ? "Instant Buy" : "Instant Sell"}
                setStepCount={(item) => setStepCount(item)}
                stepCount={stepCount}
                setTokenAddress={(item) => setTokenAddress(item)}
                tokenAddress={tokenAddress}
                setValidation={(item) => setValidation(item)}
                validation={validation}
                walletData={walletData}
                checked={checked}
                handleChange={handleChange}
                flashTab={flashTab}
                setFlashTab={setFlashTab}
                isListGetBalanceError={isListGetBalanceError}
                listGetBance={listGetBance}
                isDataLoading={isDataLoading}
                setDataLoading={setDataLoading}
                setIsListGetBalanceError={setIsListGetBalanceError}
              />
              <Container>
                <Box
                  mt={2}
                  className="display_center"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <Box width="100%" style={{ overflow: "hidden" }}>
                    <Typography variant="body1" style={{ fontSize: "14px" }}>
                      Choose or enter your amount
                    </Typography>
                    <TextField
                      placeholder="Amount"
                      type="number"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#202020",
                          height: "39px",

                          borderRadius: "10px",
                        },
                        "& .MuiOutlinedInput-input": {
                          fontSize: "14px",
                        },
                      }}
                      onKeyDown={(event) => handleNegativeValue(event)}
                      disabled={isEdit}
                      fullWidth
                      value={amount}
                      variant="outlined"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (balanceData && balanceData?.ethBalance === 0) {
                          if (parseFloat(inputValue) > 0) {
                            setAmount(balanceData?.ethBalance.toString());
                          } else {
                            setAmount(inputValue);
                          }
                        } else {
                          setAmount(inputValue);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>{`${amount}`}</Typography>
                            &nbsp;&nbsp;
                            <img
                              onClick={() => setIsEdit(false)}
                              style={{ cursor: "pointer" }}
                              src="/images/buy-sell/edit_icon.svg"
                              alt="edit"
                            />
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(event) => {
                        if (
                          event?.key === "-" ||
                          event?.key === "+" ||
                          event?.key === "*" ||
                          event?.key === "/" ||
                          event?.key === "e"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <FormHelperText error>
                      {isSubmit && amount === "" && "Please enter amount"}
                      {isSubmit && amount === 0 && "Please enter amount"}
                    </FormHelperText>

                    {type === "buy" && checked && (
                      <>
                        <Typography variant="body1">
                          Enter your token address
                        </Typography>

                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#2B3B52",
                              height: "43px",
                            },
                          }}
                          fullWidth
                          type="text"
                          placeholder="Enter your token address"
                          variant="outlined"
                          value={tokenToGetAddress}
                          onChange={(e) => {
                            if (tokenAddressRegex.test(e.target.value)) {
                              setValidation1(true);
                              setDataLoading(true);
                            } else {
                              setValidation1(false);
                              setDataLoading(false);
                            }
                            setTokenToGetAddress(e.target.value);
                          }}
                        />
                        <FormHelperText error>
                          {!validation1 &&
                            tokenToGetAddress !== "" &&
                            "Please enter valid address"}
                          {isSubmit &&
                            tokenToGetAddress === "" &&
                            "Please enter token address"}
                        </FormHelperText>
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  mt={2}
                  className="card_common display_center"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <Box p={1} width="100%" style={{ overflow: "hidden" }}>
                    <CommonLeverageSlider1
                      setAmount={(item) => setAmount(item)}
                      amount={amount}
                      type="amount"
                      walletData={balanceData?.ethBalance}
                    />
                  </Box>
                </Box>

                {ethData && (
                  <>
                    <Box
                      mt={1}
                      mb={1}
                      style={{
                        borderTop: "1px solid rgba(255, 255, 255, 0.12)",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ paddingBottom: "8px", marginTop: "8px" }}
                      >
                        Transaction Details
                      </Typography>

                      <Box className="">
                        <Box className="dispalySpacebetween">
                          <Typography
                            variant="body2"
                            style={{
                              paddingBottom: "10px",
                              color: "#ffffff7a",
                            }}
                          >
                            Amount to Receive:
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ paddingBottom: "10px" }}
                          >
                            {ethData?.amountsOUT || "--"}
                          </Typography>
                        </Box>
                        <Box className="dispalySpacebetween">
                          <Typography
                            variant="body2"
                            style={{
                              paddingBottom: "10px",
                              color: "#ffffff7a",
                            }}
                          >
                            Slippage :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ paddingBottom: "10px" }}
                          >
                            5%
                          </Typography>
                        </Box>
                        <Box className="dispalySpacebetween">
                          <Typography
                            variant="body2"
                            style={{
                              paddingBottom: "10px",
                              color: "#ffffff7a",
                            }}
                          >
                            Gas amount:
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ paddingBottom: "10px" }}
                          >
                            {ethData?.estimate_gas || "--"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="dispalySpacebetween">
                      <Typography
                        variant="body1"
                        style={{ paddingBottom: "10px" }}
                      >
                        Total Transaction fees:
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ paddingBottom: "10px" }}
                      >
                        {ethData?.estimate_gas || "--"}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box mt={1} mb={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (type === "buy" && checked) {
                        butTokenAPIHandler();
                      } else {
                        buyTokenHandler();
                      }
                    }}
                    fullWidth
                    disabled={!amount || amount === "0"}
                    style={{
                      marginBottom: "16px",
                      height: "48px",
                      borderRadius: "10px",
                      border: "none",
                      background:
                        type === "buy"
                          ? "linear-gradient(91deg, #00B05C 7.39%, #00A843 97.25%)"
                          : "linear-gradient(91deg, #00B05C 7.39%, #00A843 97.25%)",
                    }}
                  >
                    {isLoading
                      ? type === "buy"
                        ? "Buying..."
                        : "Selling..."
                      : type === "buy"
                      ? "Buy Now"
                      : "Sell Now"}{" "}
                    {isLoading && <ButtonCircular />}
                  </Button>
                </Box>
              </Container>
            </>
          )}
        </>
      )}
      {/* <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {isDataLoading && <ClipLoader color="rgb(255, 255, 255)" />}
      </Box> */}
    </Box>
  );
}
const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#366EFF",
    "&:hover": {
      backgroundColor: alpha("#366EFF", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#366EFF",
  },
  "& .iconButton": {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
      padding: "2px",
      height: "20px",
      width: "20px",
      background: "#232323",
    },
  },
}));

const CommonStep = ({
  title,
  stepCount,
  setStepCount,
  setTokenAddress,
  tokenAddress,
  setValidation,
  validation,
  walletData,
  handleChange,
  setChecked,
  checked,
  type,
  flashTab,
  setFlashTab,
  isListGetBalanceError,
  setIsListGetBalanceError,
  listGetBance,
  isDataLoading,
  setDataLoading,
}) => {
  const header = {
    padding: "0px",
    "& button": {
      background: "#9b9ba130",
      fontSize: "10px",
      borderRadius: "30px",
      fontWeight: "300",
      "& svg": {
        fontSize: "15px !important",
        color: "#9B9BA1",
      },
      "& .MuiButton-startIcon": {
        marginRight: "4px",
        margiLeft: "0px",
      },
    },
    "& .copyIcon": {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
  };
  const iconButton = {
    margin: "16px 0px",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
      padding: "2px",
      height: "20px",
      width: "20px",
      background: "#232323",
    },
  };
  const regex = /^0x[0-9A-Fa-f]{40}$/;
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();
  const handleCopyAddress = () => {
    navigator.clipboard
      .writeText(tokenAddress)
      .then(() => {
        // console.log("Address copied to clipboard:", tokenAddress);
        toast.success("Address copied successfully");
      })
      .catch((error) => {
        // console.error("Failed to copy address:", error);
        toast.error("Failed to copy address");
      });
  };
  return (
    <TabMainBox>
      <Box>
        <Container>
          {(stepCount === 1 || stepCount === 2) && (
            <Box mb={2}>
              <Box mb={2} className="iconButton displaySpacebetween">
                <Typography>Instant Buy</Typography>
                <IconButton onClick={() => navigate(-1)}>
                  <RxCross2 />
                </IconButton>
              </Box>

              {/* <Box className="displayCenter" mt={2}>
                <Typography style={{ fontFamily: "Gilroy-ExtraBold" }}>
                  ETH
                </Typography>

                <FormControlLabel
                  control={
                    <PinkSwitch checked={checked} onChange={handleChange} />
                  }
                />
                <Typography style={{ fontFamily: "Gilroy-ExtraBold" }}>
                  Token
                </Typography>
              </Box> */}
            </Box>
          )}

          <Box className="mainWalletBox">
            <Box className="ButtonBox">
              <Button
                className={flashTab === "ETH" ? "activtab" : "butontab"}
                onClick={() => setFlashTab("ETH")}
              >
                <Typography variant="h6">ETH</Typography>
              </Button>
              <Button
                className={flashTab === "Token" ? "activtab" : "butontab"}
                onClick={() => setFlashTab("Token")}
              >
                <Typography variant="h6">Token</Typography>
              </Button>
            </Box>
          </Box>

          {flashTab === "ETH" && (
            <>
              <Box sx={header} mb={2}>
                <Box sx={iconButton}>
                  <Typography>Token Address</Typography>
                  {/* <IconButton onClick={() => navigate(-1)}>
                    <IoMdArrowRoundBack color="#FFF" />
                  </IconButton> */}
                  <Box className="copyIcon">
                    <Button
                      disabled={!tokenAddress}
                      onClick={handleCopyAddress}
                      startIcon={<IoMdCopy />}
                    >
                      Copy
                    </Button>
                  </Box>
                </Box>

                {/* <Box className="copyIcon">
                  <Button startIcon={<IoMdCopy />}>Copy</Button>
                </Box> */}
              </Box>
              <TextField
                fullWidth
                type="text"
                placeholder="0x00...0000"
                variant="outlined"
                value={tokenAddress}
                onChange={(e) => {
                  setDataLoading(false);
                  if (regex.test(e.target.value)) {
                    setValidation(true);
                    setIsListGetBalanceError(false);
                  } else {
                    setValidation(false);
                    setIsListGetBalanceError(false);
                    
                  }
                  setTokenAddress(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {tokenAddress && isDataLoading && (
                        <Box position="relative">
                          <ClipLoader size="20px" color="#FFFFFF" />
                        </Box>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText error>
                {!validation &&
                  tokenAddress !== "" &&
                  "Please enter valid address"}
              </FormHelperText>

              {stepCount === 1 && (
                <Box>
                  <DesAccordian
                    type="step1"
                    sellOrBuy={type === "sell"}
                    walletData={walletData}
                  />
                </Box>
              )}
              {stepCount === 2 && (
                <Box>
                  <DesAccordian
                    type="step2"
                    sellOrBuy={type === "sell"}
                    walletData={walletData}
                  />
                </Box>
              )}
              {(stepCount === 1 || stepCount === 0) && (
                <Box mt={4}>
                  <Button
                    variant="contained"
                    disabled={
                      !isListGetBalanceError 
                    }
                    // disabled={!validation || (flashTab === "ETH" && !isListGetBalanceError && !isDataLoading)}
                    // disabled={!validation || (flashTab === "ETH" && !isListGetBalanceError && !isDataLoading)}
                    color="primary"
                    onClick={() => setStepCount()}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            </>
          )}
          {flashTab === "Token" && (
            <>
              <Box mb={2} mt={2}>
                {/* <Box className="displaySpacebetween">
                  <Typography variant="body2">CA: </Typography>
                  <Box className="displayStart">
                    <Typography variant="body2">sfddfo....bdsfde</Typography>
                    <IoMdCopy
                      color="#13C5F8"
                      onClick={() => {
                        toast.success("Coppied Sucessfully");
                      }}
                    />
                  </Box>
                </Box>
                <Box className="displaySpacebetween" mt={1} mb={1}>
                  <Typography variant="body2">Name: </Typography>
                  <Typography variant="body2">USDT</Typography>
                </Box> */}
              </Box>
              <TextField
                fullWidth
                type="text"
                placeholder="Enter your token address"
                variant="outlined"
                value={tokenAddress}
                onChange={(e) => {
                  if (regex.test(e.target.value)) {
                    setValidation(true);
                  } else {
                    setValidation(false);
                  }
                  setTokenAddress(e.target.value);
                }}
              />
              <FormHelperText error>
                {!validation &&
                  tokenAddress !== "" &&
                  "Please enter valid address"}
              </FormHelperText>
              {stepCount === 1 && (
                <Box>
                  <DesAccordian
                    type="step1"
                    sellOrBuy={type === "sell"}
                    walletData={walletData}
                  />
                </Box>
              )}
              {stepCount === 2 && (
                <Box>
                  <DesAccordian
                    type="step2"
                    sellOrBuy={type === "sell"}
                    walletData={walletData}
                  />
                </Box>
              )}
              {(stepCount === 1 || stepCount === 0) && (
                <Box mt={4}>
                  <Button
                    variant="contained"
                    disabled={tokenAddress === "" || !validation}
                    color="primary"
                    onClick={() => setStepCount()}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
    </TabMainBox>
  );
};
