import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import { Avatar, Box, Button, Container, Typography } from "@mui/material";
import BackButton from "src/Components/commonbackbutton/BackButton";
import ButtonCircular from "src/Components/ButtonCircular";

export default function AlphaScan() {
  const [isDataLoading, setDataLoading] = React.useState(true);
  const [topInfluencer, setTopInfluencer] = React.useState({});
  console.log("topInfluencertopInfluencer", topInfluencer);
  const [topSeries, setTopSeries] = React.useState({});
  const [changeInterval, setChangeInterval] = React.useState({});
  const [tabView, setTabView] = React.useState("Change Interval");
  const getTokenInfoHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getTokenInfo,
      });

      if (res.data) {
        setDataLoading(false);
        setTopInfluencer(res.data.top_influencer);
        setTopSeries(res.data.tokenseries);
        setChangeInterval(res.data.changeInterval);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  React.useEffect(() => {
    getTokenInfoHandler();
  }, []);
  return (
    <Box>
      <BackButton title="Alpha Scan" />
      <Container>
        <Box display="flex" mb={3}>
          {["Change Interval", "Tokenseries", "Influencer"].map((item, i) => {
            return (
              <Button
                fullWidth
                style={{
                  borderRadius: "10px",
                  margin: "3px",
                  whiteSpace: "pre",
                }}
                variant={tabView === item ? "contained" : "outlined"}
                onClick={() => setTabView(item)}
              >
                {item}
              </Button>
            );
          })}
        </Box>

        {isDataLoading ? (
          <Box width="100%" className="displayCenter">
            <ButtonCircular />
          </Box>
        ) : (
          <>
            {tabView === "Influencer" && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Profile Image</TableCell>
                      <TableCell>Engagement</TableCell>
                      <TableCell>Engagement Rank</TableCell>
                      <TableCell>Followers</TableCell>
                      <TableCell>Followers Rank</TableCell>
                      <TableCell>Influencer Rank</TableCell>
                      <TableCell>Medium</TableCell>
                      <TableCell>Twitter Screen Name</TableCell>
                      <TableCell>Volume</TableCell>
                      <TableCell>Volume Rank</TableCell>
                      <TableCell>Weighted Average Rank</TableCell>
                    </TableRow>
                  </TableHead>
                  {topInfluencer && topInfluencer && (
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          {topInfluencer?.display_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Avatar src={topInfluencer?.profile_image} />
                        </TableCell>
                        <TableCell>{topInfluencer?.engagement}</TableCell>
                        <TableCell>{topInfluencer?.engagement_rank}</TableCell>
                        <TableCell>{topInfluencer?.followers}</TableCell>
                        <TableCell>{topInfluencer?.followers_rank}</TableCell>
                        <TableCell>{topInfluencer?.influencer_rank}</TableCell>
                        <TableCell>{topInfluencer?.medium}</TableCell>
                        <TableCell>
                          {topInfluencer?.twitter_screen_name}
                        </TableCell>
                        <TableCell>{topInfluencer?.volume}</TableCell>
                        <TableCell>{topInfluencer?.volume_rank}</TableCell>
                        <TableCell>
                          {topInfluencer?.weighted_average_rank}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
            {tabView === "Tokenseries" && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Alt Rank</TableCell>
                      <TableCell>Alt Rank 30d</TableCell>
                      <TableCell>Asset Id</TableCell>
                      <TableCell>close</TableCell>
                      <TableCell>High</TableCell>
                      <TableCell>Low</TableCell>
                      <TableCell>Market Cap</TableCell>
                      <TableCell>Market Cap Rank</TableCell>
                      <TableCell>Open</TableCell>
                      <TableCell>Percent Change 24h Rank</TableCell>
                      <TableCell>Social Contributors</TableCell>
                      <TableCell>Social Score</TableCell>
                      <TableCell>Social Score 24h Rank</TableCell>
                      <TableCell>Social Volume</TableCell>
                      <TableCell>Social Volume 24h Rank</TableCell>
                    </TableRow>
                  </TableHead>
                  {topInfluencer && topInfluencer && (
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{topSeries?.alt_rank}</TableCell>
                        <TableCell component="th" scope="row">
                          {topSeries?.alt_rank_30d}
                        </TableCell>
                        <TableCell>{topSeries?.asset_id}</TableCell>
                        <TableCell>{topSeries?.close}</TableCell>
                        <TableCell>{topSeries?.high}</TableCell>
                        <TableCell>{topSeries?.low}</TableCell>
                        <TableCell>{topSeries?.market_cap}</TableCell>
                        <TableCell>{topSeries?.market_cap_rank}</TableCell>
                        <TableCell>{topSeries?.open}</TableCell>
                        <TableCell>
                          {topSeries?.percent_change_24h_rank}
                        </TableCell>
                        <TableCell>{topSeries?.social_contributors}</TableCell>
                        <TableCell>{topSeries?.social_score}</TableCell>
                        <TableCell>
                          {topSeries?.social_score_24h_rank}
                        </TableCell>
                        <TableCell>{topSeries?.social_volume}</TableCell>
                        <TableCell>
                          {topSeries?.social_volume_24h_rank}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
            {tabView === "Change Interval" && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Alt Rank 1D</TableCell>
                      <TableCell>Alt Rank 1D Percent Change</TableCell>
                      <TableCell>Alt Rank 1D Previous</TableCell>
                      <TableCell>Average Sentiment 1D</TableCell>
                      <TableCell>Average Sentiment 1D Previous</TableCell>
                      <TableCell>bearish sentiment 1d</TableCell>
                      <TableCell>bearish sentiment 1d percent change</TableCell>
                      <TableCell>bearish sentiment 1d previous</TableCell>
                      <TableCell>bullish sentiment 1d</TableCell>
                      <TableCell>bullish sentiment 1d percent change</TableCell>
                      <TableCell>bullish sentiment 1d previous</TableCell>
                      <TableCell>close</TableCell>
                      <TableCell>close 1d</TableCell>
                      <TableCell>close 1d percent change</TableCell>
                      <TableCell>close 1d previous</TableCell>
                      <TableCell>correlation rank 1d</TableCell>
                      <TableCell>correlation rank 1d percent change</TableCell>
                      <TableCell>correlation rank 1d previous</TableCell>
                      <TableCell>data points 1d</TableCell>
                      <TableCell>data points 1d previous</TableCell>

                      <TableCell>galaxy score 1d</TableCell>
                      <TableCell>galaxy score 1d percent change</TableCell>
                      <TableCell>galaxy score 1d previous</TableCell>
                      <TableCell>id</TableCell>
                      <TableCell>market cap</TableCell>
                      <TableCell>market cap 1d</TableCell>
                      <TableCell>market cap 1d percent change</TableCell>

                      <TableCell>market cap 1d previous</TableCell>
                      <TableCell>market dominance 1d</TableCell>
                      <TableCell>name</TableCell>
                      <TableCell>previous price btc</TableCell>
                      <TableCell>price btc</TableCell>
                      <TableCell>price btc 1d percent change</TableCell>

                      <TableCell>price btc 1d previous</TableCell>
                      <TableCell>reddit volume 1d</TableCell>
                      <TableCell>reddit volume 1d percent change</TableCell>
                      <TableCell>reddit volume 1d previous</TableCell>
                      <TableCell>social contributors 1d</TableCell>
                      <TableCell>
                        social contributors 1d percent change
                      </TableCell>

                      <TableCell>social contributors 1d previous</TableCell>
                      <TableCell>social dominance 1d</TableCell>
                      <TableCell>social dominance 1d percent change</TableCell>
                      <TableCell>social dominance 1d previous</TableCell>
                      <TableCell>social score 1d</TableCell>
                      <TableCell>social score 1d percent change</TableCell>
                      <TableCell>social score 1d previous</TableCell>
                      <TableCell>social volume 1d</TableCell>
                      <TableCell>social volume 1d percent change</TableCell>
                      <TableCell>social volume 1d percent change</TableCell>
                    </TableRow>
                  </TableHead>
                  {changeInterval && changeInterval && (
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{changeInterval?.alt_rank_1d}</TableCell>
                        <TableCell component="th" scope="row">
                          {changeInterval?.alt_rank_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.alt_rank_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.average_sentiment_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.average_sentiment_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.bearish_sentiment_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.bearish_sentiment_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.bearish_sentiment_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.bullish_sentiment_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.bullish_sentiment_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.bullish_sentiment_1d_previous}
                        </TableCell>
                        <TableCell>{changeInterval?.close}</TableCell>
                        <TableCell>{changeInterval?.close_1d}</TableCell>
                        <TableCell>
                          {changeInterval?.close_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.close_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.correlation_rank_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.correlation_rank_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.correlation_rank_1d_previous}
                        </TableCell>
                        <TableCell>{changeInterval?.data_points_1d}</TableCell>
                        <TableCell>
                          {changeInterval?.data_points_1d_previous}
                        </TableCell>
                        <TableCell>{changeInterval?.galaxy_score_1d}</TableCell>
                        <TableCell>
                          {changeInterval?.galaxy_score_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.galaxy_score_1d_previous}
                        </TableCell>
                        <TableCell>{changeInterval?.id}</TableCell>
                        <TableCell>{changeInterval?.market_cap}</TableCell>
                        <TableCell>{changeInterval?.market_cap_1d}</TableCell>
                        <TableCell>
                          {changeInterval?.market_cap_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.market_cap_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.market_dominance_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.market_dominance_1d_percent_change}
                        </TableCell>
                        <TableCell>{changeInterval?.name}</TableCell>
                        <TableCell>
                          {changeInterval?.previous_price_btc}
                        </TableCell>
                        <TableCell>{changeInterval?.price_btc}</TableCell>
                        <TableCell>
                          {changeInterval?.price_btc_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.price_btc_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.reddit_volume_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.reddit_volume_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.reddit_volume_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_contributors_1d}
                        </TableCell>
                        <TableCell>
                          {
                            changeInterval?.social_contributors_1d_percent_change
                          }
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_contributors_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_dominance_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_dominance_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_dominance_1d_previous}
                        </TableCell>
                        <TableCell>{changeInterval?.social_score_1d}</TableCell>
                        <TableCell>
                          {changeInterval?.social_score_1d_percent_change}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_score_1d_previous}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_volume_1d}
                        </TableCell>
                        <TableCell>
                          {changeInterval?.social_volume_1d_percent_change}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Container>
    </Box>
  );
}
