import React from "react";
import ReactDOM from "react-dom/client";
import "src/scss/main.css";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { ToastContainer, toast } from "react-toastify";
import { createCustomTheme } from "src/Themes/index";
import "react-toastify/dist/ReactToastify.css";

const theme = createCustomTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
const toastStyle = {
  style: {

  background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
  color: "#fff",
  }
};
root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer position="bottom-center" autoClose={1500} toastStyle={{ backgroundColor: "linear-gradient(60deg, #29323c 0%, #485563 100%)" }} />
    <App />
  </ThemeProvider>
);
