import React from "react";
import { FormHelperText, TextField } from "@mui/material";
export default function CommonTextField({
  placeHolder,
  formData,
  setFormData,
  name,
  regex,
  setValidation,
  validation,
  isSubmit,
  type,
  errorMsg,
  errorMsg1,
  data,
  isLoading,
}) {
  return (
    <div>
      <TextField
        placeholder={placeHolder}
        fullWidth
        value={data ? data : ""}
        disabled={isLoading ? isLoading : ""}
        type={type}
        name={name}
        onChange={(e) => {
          if (regex !== "" && regex.test(e.target.value)) {
            setValidation(true);
          } else {
            setValidation(false);
          }
          setFormData({
            ...formData,
            [name]: e.target.value,
          });
        }}
      />
      <FormHelperText error>
        {isSubmit && data === "" && errorMsg ? errorMsg : ""}
        {!validation && data !== "" && errorMsg1 ? errorMsg1 : ""}
      </FormHelperText>
    </div>
  );
}
