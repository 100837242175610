import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Grid, Pagination } from "@mui/material";

import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import moment from "moment";
import { bscApikey, ethApikey } from "src/Constant";
import AccordianCommon from "./AccordianCommon";
import SwipeableEdgeDrawer from "./Bottomnavigation/BottomButton";
import ButtonCircular from "./ButtonCircular";
import ListLoder from "./Skeletons/ListLoder";
import ClipLoader from "react-spinners/ClipLoader";
const mainContainer = {
  "& span": {
    color: "#FFF",
  },
  "& .ttt": {
    color: "rgba(255, 255, 255, 0.60)",
    textAlign: "end",
  },
  "& .nodata": {
    textAlign: "center",
    color: "#FFF",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",
    marginLeft: "44%",
  },
};
export default function ActiveTrade() {
  const [open, setOpen] = useState(false);

  let getChain = window.localStorage.getItem("chain_id");
  let connectChain = getChain ? getChain : "eth";
  const tnxHis = [{}];
  const [expanded, setExpanded] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [isloading, setisloading] = React.useState(true);
  const [isloadingdata, setisloadingData] = React.useState(true);
  const [transactionList, settransactionList] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nofOfPage, setNoOfPage] = useState(10);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    amount: "",
    fromDate: "",
    toDate: "",
  });
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const _onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const temp = { ...filterData, [name]: value };

    setFilterData(temp);
  };
  const indexOfLastItem = currentPage * nofOfPage;
  const indexOfFirstItem = indexOfLastItem - nofOfPage;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function fetchEthTransactions(walletAddress) {
    let apiUrl;
    if (connectChain == "eth") {
      apiUrl = `https://api-goerli.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=desc&limit=10&page=1&apikey=${ethApikey}`;
    }
    if (connectChain == "bsc") {
      apiUrl = `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=desc&limit=10&page=1&apikey=${bscApikey}`;
    }
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setisloading(false);
      if (data.status === "1") {
        const transactions = data.result;
        settransactionList(transactions);
        setisloading(false);
        setShowNoDataMessage(false); // Resetting the timer as data is loaded
      } else {
        console.error("Error fetching Ethereum transactions:", data.message);
      }
    } catch (error) {
      console.error("Error fetching Ethereum transactions:", error);
      setisloading(false);
    }
  }

  const finalData = useMemo(() => {
    if (
      filterData.amount !== "" ||
      filterData.fromDate !== "" ||
      filterData.toDate !== ""
    ) {
      return (
        transactionList &&
        transactionList.filter((data) => {
          return (
            Number(data?.timeStamp) * 1000 >=
              moment(filterData.fromDate).unix() * 1000 ||
            Number(data?.timeStamp) * 1000 >=
              moment(filterData.toDate).unix() * 1000 ||
            (filterData.amount &&
              (Number(data?.value) + Number(data?.gasUsed))
                ?.toString()
                ?.indexOf(filterData.amount) !== -1)
          );
        })
      );
    } else {
      return transactionList;
    }
  }, [transactionList, filterData]);
  //
  const activeTrade = async () => {
    try {
      setisloadingData(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.activeTrade,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
        },
      });
      if (res.data.responseMessage === "Data found successfully") {
        setisloadingData(false);
        setData(res.data.active_token);
      } else {
        setisloadingData(false);
      }
    } catch (error) {
      setisloadingData(false);
    }
  };

  useEffect(() => {
    activeTrade();
  }, []);

  const getCurentWalletHandler = async () => {
    try {
      let res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.currentWallet,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
        },
      });

      if (res.data) {
        if (res.data.publicKey) {
          fetchEthTransactions(res.data.publicKey);
        } else {
          setisloading(false);
          setMessage(res.data.result);
        }
      } else {
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
    }
  };
  useEffect(() => {
    getCurentWalletHandler();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNoDataMessage(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [isloadingdata]);

  const onPageChange = (event, value) => {
    setCurrentPage(value);
  };
  const currentItems = [
    {
      token: "SPX 6900",
      time: "2d 12h 5m",
      amount: "0.1 Eth",
      value: "0.2 Eth+100%",
    },
    {
      token: "SPX 6900",
      time: "2d 12h 5m",
      amount: "0.1 Eth",
      value: "0.2 Eth+100%",
    },
    {
      token: "SPX 6900",
      time: "2d 12h 5m",
      amount: "0.1 Eth",
      value: "0.2 Eth+100%",
    },
    {
      token: "SPX 6900",
      time: "2d 12h 5m",
      amount: "0.1 Eth",
      value: "0.2 Eth+100%",
    },
    {
      token: "SPX 6900",
      time: "2d 12h 5m",
      amount: "0.1 Eth",
      value: "0.2 Eth+100%",
    },
  ];
  return (
    <>
      {isloadingdata ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "4vh",
          }}
        >
          <ClipLoader color={"#FFF"} loading={isloadingdata} size={35} />
        </div>
      ) : (
        <Box pb={4}>
          <Box sx={mainContainer}>
            <Grid container>
              {data.length === 0 && showNoDataMessage ? (
                <Typography className="nodata">No data found</Typography>
              ) : (
                data.map((item, i) => (
                  <Grid item xs={12} key={i}>
                    <AccordianCommon
                      item={item}
                      index={i}
                      expanded={expanded}
                      isloading={isloading}
                      handleChange={(item) => handleChange(item)}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Box>
      )}
      {open && (
        <SwipeableEdgeDrawer
          filterData={filterData}
          _onInputChange={(item) => _onInputChange(item)}
          open={open}
          setOpen={(item) => setOpen(item)}
          setFilterData={(item) => setFilterData(item)}
        />
      )}
    </>
  );
}
