import { Box } from "@mui/material";
import React from "react";
import BackButton from "./commonbackbutton/BackButton";

export default function Chart() {
  let searchKey = window.location.search.split("?")[1];
  console.log(" ------ searchKey", searchKey);
  return (
    <Box>
      <BackButton title="Chart" />

      <iframe
        id="dextools-widget"
        title="DEXTools Trading Chart"
        width="100%"
        height="520px"
        src={`https://www.dextools.io/widget-chart/en/ether/pe-light/${searchKey}`}
        // src={`https://www.dextools.io/widget-chart/en/ether/pe-light/${searchKey}?theme=light&chartType=2&chartResolution=30&drawingToolbars=false`}
      />
    </Box>
  );
}





