import React from "react";
import { Box } from "@mui/material";
import BackButton from "src/Components/commonbackbutton/BackButton";
import AccordinCard from "./AccordinCard";
import ActiveTrade from "src/Components/ActiveTrade";

export default function ActiveDetails() {
  return (
    <>
      <BackButton title="Active Trades" />
      <Box>
        <AccordinCard />
        <ActiveTrade />
      </Box>
    </>
  );
}
