import CommonCard from "src/Components/CommonCard";

import Share from "src/pages/Share/Share";
import SoldDetails from "src/pages/Share/SoldDetails";
import ActiveDetails from "src/pages/ActiveDetails/ActiveDetails";
import React from "react";
import SnipingCard from "src/Components/leverageSniping/SnipingCard";
import Deposite from "src/Components/Deposite";
import ProfitAttain from "src/Components/ProfitAttain";
import TokenBought from "src/pages/tokenbought/TokenBought";
import Chart from "src/Components/Chart";
import AlphaScan from "src/pages/alphascan/AlphaScan";
import AlphaScans from "src/pages/tokenbought/AlphaScans";
import ActiveTradePage from "src/Components/ActiveTradePage";
import InstantBuy from "src/Components/snipe/InstantBuy";
import Setting from "src/Components/snipe/Setting";
import EditTransdDetails from "src/Components/snipe/EditTransdDetails";

const HomePage = React.lazy(() => import("../pages/Home"));
const BuySection = React.lazy(() => import("../Components/buyModal/BuyModal"));
const LeverageBuyModal = React.lazy(() =>
  import("../Components/leverageBuy/LeverageBuyModal")
);
const WalletDetailsComponent = React.lazy(() =>
  import("../Components/walletDetails/WalletDetails")
);
const GodMode = React.lazy(() =>
  import("src/Components/currentwallet/GodMode")
);
const FundHeld = React.lazy(() =>
  import("src/Components/currentwallet/FundHeld")
);
const TransactionHistory = React.lazy(() =>
  import("src/Components/currentwallet/TransactionHistory")
);
const WalletMonetring = React.lazy(() =>
  import("src/Components/walletMonetring/WalletMonetring")
);
const CurrentWallet = React.lazy(() =>
  import("src/Components/currentwallet/CurrentWallet")
);
const TokenSold = React.lazy(() =>
  import("src/Components/TokenSold/TokenSold")
);
const Snipe = React.lazy(() => import("src/Components/snipe/Snipe"));
const routes = [
  {
    path: "/",
    element: HomePage,
  },
  {
    path: "/sold-view",
    element: SoldDetails,
  },
  {
    path: "/share",
    element: Share,
  },
  {
    path: "/active-trader",
    element: ActiveTradePage,
  },
  {
    path: "/activeDetails",
    element: ActiveDetails,
  },
  {
    path: "/wallet",
    element: CommonCard,
  },
  {
    path: "/buy-token",
    element: BuySection,
  },
  {
    path: "/token-sold",
    element: TokenSold,
  },
  {
    path: "/leverage-buy",
    element: LeverageBuyModal,
  },
  {
    path: "/wallet-details",
    element: WalletDetailsComponent,
  },
  {
    path: "/current-wallet",
    element: CurrentWallet,
  },
  {
    path: "/transaction-history",
    element: TransactionHistory,
  },
  {
    path: "/token-hold",
    element: FundHeld,
  },
  {
    path: "/god-mode",
    element: GodMode,
  },
  {
    path: "/deposite",
    element: SnipingCard,
  },
  {
    path: "/make-contribution",
    element: Deposite,
  },
  {
    path: "/profit-attain",
    element: ProfitAttain,
  },
  {
    path: "/token-bought",
    element: TokenBought,
  },

  {
    path: "/Chart",
    element: Chart,
  },
  {
    path: "/alpha-scan",
    element: AlphaScan,
  },
  {
    path: "/alpha-scans",
    element: AlphaScans,
  },
  {
    path: "/wallet-monetring",
    element: WalletMonetring,
  },
  {
    path: "/snipping",
    element: Snipe,
  },
  {
    path: "/instant-buy",
    element: InstantBuy,
  },
  {
    path: "/setting",
    element: Setting,
  },
  {
    path: "/edit-transaction",
    element: EditTransdDetails,
  },

  // {
  //   path: "/404",
  //   element: PageNotFound,
  //   // layout: HomeLayout,
  // },
];
export default routes;
