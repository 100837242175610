import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SpotModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClickOpen}
        style={{
          width: "auto",
          height: "auto",
          position: "absolute",
          right: "38%",
          top: "3px",
          cursor: "pointer",
          zIndex: "99999",
        }}
      >
        <img src="images/info.svg" />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            p: 2,
            textAlign: "left",
            background: "rgb(23 23 23)",
            borderBottom: "1px solid #8080801f",
            borderRadius: "10px 0 0",
          }}
          id="customized-dialog-title"
        >
          Toggle and Sort
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src="images/close.svg" />
        </IconButton>
        <DialogContent dividers>
          <Typography variant="body1">What does toggling do?</Typography>
          <Typography
            variant="body2"
            color="rgba(255, 255, 255, 0.60)"
            mt={1}
            style={{ fontSize: "12px" }}
          >
            Lorem ipsum may be used as a placeholder before final copy is
            available.Lorem ipsum may be used as a placeholder before final copy
            is available.
          </Typography>
          <Box mt={3}>
            <Typography variant="body1">What does toggling do?</Typography>
            <Typography
              variant="body2"
              color="rgba(255, 255, 255, 0.60)"
              mt={1}
              style={{ fontSize: "12px" }}
            >
              Lorem ipsum may be used as a placeholder before final copy is
              available.Lorem ipsum may be used as a placeholder before final
              copy is available.
            </Typography>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}
