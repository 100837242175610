import React, { useState } from "react";
import { Avatar, Box, Button, Container, Typography } from "@mui/material";
import BackButton from "src/Components/commonbackbutton/BackButton";
import SwitchToggle from "./Switch";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import SpotModal from "./SpotModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const transactiondetails = [
  {
    name: "Buying Time ",
    price: "54.222",
  },
  {
    name: "Entry Price",
    price: "54.222",
  },
  {
    name: "Exit Price",
    price: "354.222",
  },
  {
    name: "Duration",
    price: "1d 2h 50m 20s",
  },
  {
    name: "Amount Earned",
    price: "24.333 ETH",
  },
];

export default function Share() {
  const navigate = useNavigate();
  const [transactionDetails, setTransactionDetails] = useState(transactiondetails);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(transactionDetails);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTransactionDetails(items);
  };

  return (
    <>
      <Box style={{ position: "relative" }}>
        <SpotModal /> <BackButton title="Toggle and Sort" />
        <Container style={{ minHeight: "400px" }}>
          <Box
            className="displaySpacebetween"
            style={{
              paddingBottom: "12px",
            }}
          >
            <Box className="displayStart">
              <Avatar src="images/solana.png" className="avtarBox" />
              <Box ml={2}>
                <Typography variant="body1">SolUSDT</Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#2EC085", fontSize: "12px" }}
                >
                  Long
                </Typography>
                <Typography variant="h6" style={{ color: "#2EC085" }} mt={1}>
                  +259.4949
                </Typography>
              </Box>
            </Box>
            <SwitchToggle />
          </Box>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="transactions">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ minHeight: "300px" }} 
                >
                  {transactionDetails.map((data, index) => (
                    <Draggable
                      key={index}
                      draggableId={`item-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          key={index}
                          className="displaySpacebetween"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            borderTop: "1px solid rgba(128, 128, 128, 0.14)",
                            paddingTop: "13px",
                            opacity: snapshot.isDragging ? 0.5 : 1,
                          }}
                        >
                          <Box className="displayStart" >
                            <Box {...provided.dragHandleProps}>
                              <MenuIcon style={{ color: "#808080ad" }} />
                            </Box>
                            <Box ml={2}>
                              <Typography
                                variant="body2"
                                style={{
                                  paddingBottom: "3px",
                                  fontSize: "11px",
                                  color: "rgba(255, 255, 255, 0.75)",
                                }}
                              >
                                {data?.name}
                              </Typography>
                              <Typography
                                variant="h5"
                                style={{ paddingBottom: "10px", color: "#fff" }}
                              >
                                {data?.price}
                              </Typography>
                            </Box>
                          </Box>
                          <SwitchToggle style={{ visibility: snapshot.isDragging ? 'hidden' : 'visible' }} /> 
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/sold-view")}
            >
              Next
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}
