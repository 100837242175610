import BackButton from "src/Components/commonbackbutton/BackButton";
import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import ButtonCircular from "src/Components/ButtonCircular";
import { useHistory } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { sortAddress, sortAddressTrans } from "src/utils";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDivider from "src/Components/CommonDivider";
import Alpha from "./Alpha";
import Trade from "./Trade";
import BuySection from "src/Components/buyModal/BuyModal";

const tabBox = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "35px",
  flex: 1,
  "& button": {
    width: "160px",
    borderRadius: "15px",
  },
};
const borderBottom = {
  marginTop: "-20px",
  marginBottom: "22px",
};

const iconButton = {
  display: "flex",
  justifyContent: "space-between",
  "& .MuiIconButton-root": {
    padding: "2px",
    height: "20px",
    width: "20px",
    background: "#232323",
  },
};

const commonStyle = {
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingTop: "30px",
};
const textStyle = {
  fontFamily: "Gilroy-SemiBold",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "170.3%",
};
export default function TokenBought() {
  const navigate = useNavigate();
  const [tabView, setTabView] = useState("Alpha");
  console.log("tabViewtabView", tabView);
  const location = useLocation();
  const type = location.state;
  const [expanded, setExpanded] = useState(false);
  const [isDataLoading, setDataLoading] = useState(true);
  const [checkBalance, setCheckBalance] = useState();
  console.log("hxdygggdhhgu", checkBalance);
  const [walletData, setWalletData] = useState({});
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getCurentWalletHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url:
          type === "tokenSold"
            ? ApiConfig.getTokenSold
            : ApiConfig.getCurrentTokenHolding,
        bodyData: {
          chatId: window.localStorage.getItem("_chId"),
        },
      });

      if (res.data) {
        setDataLoading(false);
        setWalletData(res.data);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getCurentWalletHandler();
  }, []);

  return (
    <Box>
      {/* <BackButton
        title={type === "tokenSold" ? "Sold Token" : "Token Bought"}
      /> */}
      <BackButton
        title={type === "tokenSold" ? "Sold Token" : ""}
      />
      <Container>
        {isDataLoading ? (
          <Box className="displayCenter" width="100%">
            <ButtonCircular />
          </Box>
        ) : (
          <>
            <Box sx={tabBox}>
              <Button
                className={`tabButton ${tabView === "Alpha" ? "active" : ""}`}
                onClick={() => setTabView("Alpha")}
              >
                Alpha Scan
              </Button>
              <Button
                className={`tabButton ${tabView === "Trade" ? "active" : ""}`}
                onClick={() => setTabView("Trade")}
              >
                Trade
              </Button>
            </Box>
            <Box sx={borderBottom}>
              <CommonDivider />
            </Box>
            {tabView === "Alpha" && (
              <Alpha
                checkBalance={checkBalance}
                setCheckBalance={(e) => setCheckBalance(e)}
              />
            )}
            {tabView === "Trade" &&
              (Number(checkBalance) > 0 ? (
                <BuySection buyType={"sell"} />
              ) : (
                <BuySection buyType={"buy"} />
              ))}

            {type === "tokenSold" && (
              <>
                {walletData &&
                  walletData?.tokensInfo != 0 &&
                  walletData?.tokensInfo?.map((item, i) => {
                    return (
                      <SoldAccordian
                        handleChange={handleChange}
                        expanded={expanded}
                        index={i}
                        item={item}
                      />
                    );
                  })}
                {walletData && Number(walletData?.tokensInfo) === 0 && (
                  <Typography textAlign="center">Data Not found</Typography>
                )}
                {walletData && walletData?.result && (
                  <Typography textAlign="center">
                    {" "}
                    {walletData && walletData?.result}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Box>
  );
}

const SoldAccordian = ({ item, index, expanded, handleChange }) => {
  const textPrimary = {
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "Gilroy-Medium",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  };
  const textSecondary = {
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "Gilroy-Medium",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  };
  const text = {
    color: "#FFF",

    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
    paddingTop: "7px",
  };
  const navigate = useNavigate();
  return (
    <Box>
      <Accordion
        key={`transaction${index}`}
        sx={{ border: "none", margin: "10px 0px", padding: "8px" }}
        expanded={expanded === index}
        onChange={handleChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          aria-controls={item}
          id={item}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
            },
          }}
        >
          <Box>
            <Typography sx={textPrimary} variant="subTitle1">
              Token Name:
            </Typography>
          </Box>
          <Box>
            <Typography sx={textPrimary} variant="subTitle1">
              {item?.tokenSymbol}
            </Typography>
          </Box>
        </AccordionSummary>
        <CommonDivider />
        <AccordionDetails>
          <Box mb={1} className="dispalySpacebetween">
            <Box>
              <Typography sx={textSecondary} className="ttt" variant="h6">
                Price
              </Typography>
              <Typography sx={text} className="ttt" variant="h6">
                {" "}
                {item?.tokenAmount}
              </Typography>
              <Typography
                style={{ paddingTop: "7px" }}
                sx={textSecondary}
                className="ttt"
                variant="h6"
              >
                Token Contract Address :
              </Typography>
              <Typography sx={text} className="ttt" variant="h6">
                {" "}
                {item && sortAddressTrans(item?.tokenAddress)}
                <CopyToClipboard text={item && item?.tokenAddress}>
                  <IconButton
                    style={{ height: "25px", width: "25px" }}
                   
                 

                    onClick={() => toast.success("Copied",{
                      style: {
                        background: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
                        color:"#fff"
                      }
                    })}
                    
                  >
                    <img src="images/copy-icon.svg" alt="" />
                  </IconButton>
                </CopyToClipboard>
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Button
              onClick={() => navigate("/alpha-scan")}
              fullWidth
              style={{
                borderRadius: "9.207px",
                border: "1px solid var(--Linear, #69BFFE)",
              }}
            >
              Aplbha
            </Button>
            &nbsp;
            <Button
              fullWidth
              style={{
                borderRadius: "9.207px",
                border: "1px solid var(--Linear, #69BFFE)",
              }}
              onClick={() => navigate("/chart")}
            >
              Chart
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
