import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import BackButton from "../commonbackbutton/BackButton";
import { handleNegativeValue, tokenAddressRegex } from "src/utils";
export default function EditTransdDetails() {
  const [inputAddress, setInputAddress] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [amount, setAmount] = useState("");
  const [isEdit1, setIsEdit1] = useState(true);
  const [amount1, setAmount1] = useState("");
  const [isEdit2, setIsEdit2] = useState(true);
  const [amount2, setAmount2] = useState("");
  const [walletAddress, setWalletAddress] = useState();
  const navigate = useNavigate();
  return (
    <Box
      className="commanbackground"
      style={{
        position: "absolute",
        bottom: "0px",
        right: "0px",
        width: "100%",
      }}
    >
      <BackButton title="Edit Transaction Details" />
      <Box
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
        }}
      ></Box>
      <Container>
        <Box mt={2} mb={1}>
          <TextField
            placeholder="Amount to Receive:"
            type="number"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
                marginBottom: "16px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            onKeyDown={(event) => handleNegativeValue(event)}
            disabled={isEdit}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              if (Number(e.target.value) > 100) {
                e.target.value = "100";
              }
              setAmount(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography style={{ paddingTop: "3px" }}>
                    {`${amount} ETH`}
                  </Typography>
                  &nbsp;&nbsp;
                  <img
                    onClick={() => setIsEdit(false)}
                    style={{ cursor: "pointer" }}
                    src="/images/buy-sell/edit_icon.svg"
                    alt="edit"
                  />
                </InputAdornment>
              ),
            }}
            onKeyPress={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "*" ||
                event?.key === "/" ||
                event?.key === "e"
              ) {
                event.preventDefault();
              }
            }}
          />
        </Box>

        <Box mb={1}>
          <TextField
            placeholder="Slippage:"
            type="number"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
                marginBottom: "16px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            onKeyDown={(event) => handleNegativeValue(event)}
            disabled={isEdit1}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              if (Number(e.target.value) > 100) {
                e.target.value = "100";
              }
              setAmount(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography style={{ paddingTop: "3px" }}>
                    {`${amount1} %`}
                  </Typography>
                  &nbsp;&nbsp;
                  <img
                    onClick={() => setIsEdit1(false)}
                    style={{ cursor: "pointer" }}
                    src="/images/buy-sell/edit_icon.svg"
                    alt="edit"
                  />
                </InputAdornment>
              ),
            }}
            onKeyPress={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "*" ||
                event?.key === "/" ||
                event?.key === "e"
              ) {
                event.preventDefault();
              }
            }}
          />
        </Box>

        <Box>
          <TextField
            placeholder="Gas Amount:"
            type="number"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#202020",
                height: "39px",
                paddingLeft: "6px",
                borderRadius: "10px",
                marginBottom: "16px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                fontSize: "14px",
              },
            }}
            onKeyDown={(event) => handleNegativeValue(event)}
            disabled={isEdit2}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              if (Number(e.target.value) > 100) {
                e.target.value = "100";
              }
              setAmount(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography style={{ paddingTop: "3px" }}>
                    {`$ ${amount2}`}
                  </Typography>
                  &nbsp;&nbsp;
                  <img
                    onClick={() => setIsEdit2(false)}
                    style={{ cursor: "pointer" }}
                    src="/images/buy-sell/edit_icon.svg"
                    alt="edit"
                  />
                </InputAdornment>
              ),
            }}
            onKeyPress={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "*" ||
                event?.key === "/" ||
                event?.key === "e"
              ) {
                event.preventDefault();
              }
            }}
          />
        </Box>
        <Box className="displaySpacebetween">
          <Button variant="contained" color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              navigate("/instant-buy");
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
