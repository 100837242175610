import React, { memo } from "react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ClipLoader from "react-spinners/DotLoader";

const CoreLayout = memo((props) => {
  const CustomLoading = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader
          // color="#36d7b7"
          loading={true}
          // cssOverride={override}
          size={60}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  };
  return (
    <div className="core-layout">
      <Suspense fallback={<CustomLoading />}>
        <Outlet />
      </Suspense>
    </div>
  );
});

export default CoreLayout;
